import { Button } from "./ui/button"
import { PlayIcon } from "lucide-react"
import React from "react"
import { play } from "../api/ApiService"
import { useSong } from "../stores/SongStore"
import { useStoreWithEqualityFn } from "zustand/traditional"
import { playerStateStore } from "../stores/PlayerStateStore"

export function PlaySongButton({songId}) {
    const {data: song, isPending} = useSong(songId)
    const playerState = useStoreWithEqualityFn(playerStateStore)

    if(isPending) {
        return
    }

    if(song.spotifyUrl !== null && playerState.canPlay) {
        return (
            <Button
                className="bg-spotify hover:bg-spotify-dark flex flex-row gap-1 w-fit"
                onClick={() => play({songId})}>
                <PlayIcon className="w-5 h-5 fill-current"/>
                Play
            </Button>
        )
    }
}