import React, {useEffect} from "react"
import {tokenStore} from "../stores/ApiTokenStore"
import {useNavigate} from "react-router-dom"
import {useAutoQuery} from "../utils"
import {useStoreWithEqualityFn} from "zustand/traditional"
import {getYears} from "../db/DbService"
import {YearSummary} from "../components/YearSummary"
import {LoadingPage} from "./LoadingPage";

export function JourneyPage() {
    const navigate = useNavigate()
    const {token} = useStoreWithEqualityFn(tokenStore)

    useEffect(() => {
        if (token === null) {
            navigate("/")
        }
    }, [token, navigate])

    if (token === null) {
        return
    }

    return (
        <div className="flex flex-col flex-grow items-center gap-4 w-full overflow-hidden">
            <Timeline/>
        </div>
    )
}

function Timeline() {
    const {data: years, isPending} = useAutoQuery(getYears)

    if (isPending) {
        return <LoadingPage/>
    }

    return (
        <div className="flex flex-col gap-4">
            {years.map(group => <YearSummary group={group} key={group.entityId}/>)}
        </div>
    )
}