import {Card, CardContent, CardHeader} from "./ui/card"
import {ArtistWithStatsCard} from "./ArtistWithStatsCard"
import React from "react"
import {Badge} from "./ui/badge"
import {AudioWaveform, ChevronRight, Clock, Compass, Disc3, Headphones, MicVocal, Music} from "lucide-react"
import {useGenre} from "../stores/GenreStore"
import {AlbumWithStatsCard} from "./AlbumWithStatsCard"
import {SongWithStatsCard} from "./SongWithStatsCard"
import {formatHour, formatListenDuration} from "../utils"
import {Link} from "react-router-dom"

export function YearSummary({group}) {
    return (
        <Card>
            <CardHeader className="text-3xl font-bold text-accent">
                <Link to={`/year/${group.entityId}`} className="flex flex-row items-center gap-1 w-fit">
                    {group.entityId}
                    <ChevronRight className="w-8 h-8"/>
                </Link>
            </CardHeader>
            <CardContent className="flex flex-col gap-4">
                <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-x-6 gap-y-4">
                    <TopArtists artists={group.topArtists}/>
                    <TopGenres genres={group.topGenres}/>
                    <TopAlbums albums={group.topAlbums}/>
                    <TopSongs songs={group.topSongs}/>
                </div>
                <Footer group={group}/>
            </CardContent>
        </Card>
    )
}

function TopArtists({ artists }) {
    return (
        <div className="flex flex-col gap-2">
            <h3 className="text-xl font-semibold flex items-center">
                <MicVocal className="mr-2 h-6 w-6 text-accent"/>
                Top Artists
            </h3>
            <div className="grid grid-cols-3 gap-4 w-full">
                {artists.map(group => <Artist group={group} key={group.entityId}/>)}
            </div>
        </div>
    )
}

function Artist({group}) {
    return (
        <ArtistWithStatsCard group={group}/>
    )
}

function TopGenres({genres}) {
    return (
        <div className="flex flex-col gap-2">
            <h3 className="text-xl font-semibold flex items-center">
                <AudioWaveform className="mr-2 h-6 w-6 text-accent"/>
                Top Genres
            </h3>
            <div className="flex flex-row flex-wrap gap-2">
                {genres.map(group => <Genre group={group} key={group.entityId}/>)}
            </div>
        </div>
    )
}

function Genre({group}) {
    const {data: genre, isPending} = useGenre(group.entityId)

    if (isPending) {
        return
    }

    return (
        <Link to={`/genre/${group.entityId}`}>
            <Badge variant="secondary" className="text-xl font-semibold text-nowrap">
                {genre.name}
            </Badge>
        </Link>
    )
}

function TopAlbums({ albums }) {
    return (
        <div className="flex flex-col gap-2">
            <h3 className="text-xl font-semibold flex items-center">
                <Disc3 className="mr-2 h-6 w-6 text-accent"/>
                Top Albums
            </h3>
            <div className="grid grid-cols-3 gap-4">
                {albums.map(group => <Album group={group} key={group.entityId}/>)}
            </div>
        </div>
    )
}

function Album({group}) {
    return (
        <AlbumWithStatsCard group={group}/>
    )
}

function TopSongs({songs}) {
    return (
        <div className="flex flex-col gap-2">
            <h3 className="text-xl font-semibold flex items-center">
                <Music className="mr-2 h-6 w-6 text-accent"/>
                Top Songs
            </h3>
            <div className="grid grid-cols-3 gap-4">
                {songs.map(group => <Song group={group} key={group.entityId}/>)}
            </div>
        </div>
    )
}

function Song({group}) {
    return (
        <SongWithStatsCard group={group}/>
    )
}

function Footer({ group }) {
    return (
        <div className="grid grid-cols-3">
            <ListeningTime group={group}/>
            <DiscoveredArtists group={group}/>
            <Hour group={group}/>
        </div>
    )
}

function ListeningTime({group}) {
    return (
        <div className="flex items-center">
            <Headphones className="h-8 w-8 mr-3 text-accent"/>
            <div>
                <p className="text-lg text-foreground font-semibold">{formatListenDuration(group.durationMs)}</p>
                <p className="text-sm text-muted-foreground">Total Listening Time</p>
            </div>
        </div>
    )
}

function DiscoveredArtists({group}) {
    return (
        <div className="flex items-center">
            <Compass className="h-8 w-8 mr-3 text-accent"/>
            <div>
                <p className="text-lg text-foreground font-semibold">{group.discoveredArtistCount}</p>
                <p className="text-sm text-muted-foreground">New Artists Discovered</p>
            </div>
        </div>
    )
}

function Hour({group}) {
    const formattedHour = formatHour(group.topHour.entityId)

    return (
        <div className="flex items-center">
            <Clock className="h-8 w-8 mr-3 text-accent"/>
            <div>
                <p className="text-lg text-foreground font-semibold">{formattedHour}</p>
                <p className="text-sm text-muted-foreground">Top Listening Hour</p>
            </div>
        </div>
    )
}