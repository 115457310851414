import React from "react"
import {CheckIcon, HeadphonesIcon, HomeIcon, LogOut, SearchIcon, Settings, Users, XIcon} from "lucide-react"
import {Input} from "./ui/input"
import {Link, useNavigate} from "react-router-dom"
import {currentUserStore} from "../stores/CurrentUserStore"
import {LoginButton} from "./LoginButton"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "./ui/dropdown-menu"
import {Avatar, AvatarFallback, AvatarImage} from "./ui/avatar"
import {clearToken, tokenStore} from "../stores/ApiTokenStore"
import {acceptFriendRequest, getIncomingFriendRequests, logout, rejectFriendRequest} from "../api/ApiClient"
import {Popover, PopoverContent, PopoverTrigger} from "./ui/popover"
import {Button} from "./ui/button"
import {invalidateAutoQuery, useAutoQuery} from "../utils"
import {useQueryClient} from "@tanstack/react-query"
import {useStoreWithEqualityFn} from "zustand/traditional"

function Logo() {
    const {hasToken} = useStoreWithEqualityFn(tokenStore)

    return (
        <Link to={hasToken ? "/home" : "/"} className="flex items-center gap-2 text-accent">
            <HeadphonesIcon className="w-6 h-6"/>
            <span className="text-lg font-semibold">insights.fm</span>
        </Link>
    )
}

function Nav() {
    return (
        <nav
            className="flex-col hidden gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
            <Logo/>
        </nav>
    )
}

function UserInfo() {
    const currentUser = currentUserStore((state) => state.currentUser)

    return (
        <div className="flex items-center h-full">
            {currentUser ? <UserIcon/> : <LoginButton/>}
        </div>
    )
}

function UserIcon() {
    const {currentUser} = useStoreWithEqualityFn(currentUserStore)
    const navigate = useNavigate()

    const logOut = async () => {
        await logout()
        clearToken()
        navigate("/")
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Avatar className="h-12 w-12">
                    <AvatarImage src={currentUser.images[0]?.url}/>
                    <AvatarFallback>{currentUser.displayName}</AvatarFallback>
                </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
                <DropdownMenuGroup>
                    <Link to="/home">
                        <DropdownMenuItem>
                            <HomeIcon/>
                            <span>Home</span>
                        </DropdownMenuItem>
                    </Link>
                    <Link to="/settings">
                        <DropdownMenuItem>
                            <Settings/>
                            <span>Settings</span>
                        </DropdownMenuItem>
                    </Link>
                </DropdownMenuGroup>
                <DropdownMenuSeparator/>
                <DropdownMenuItem onClick={logOut}>
                    <LogOut/>
                    <span>Log out</span>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

function FriendsButton() {
    const queryClient = useQueryClient()

    let {data: incomingFriendRequests, isPending} = useAutoQuery(getIncomingFriendRequests, 3)

    if (isPending) {
        return null
    }

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button variant="ghost" size="icon" className="relative">
                    <Users className="h-5 w-5"/>
                    {incomingFriendRequests.length > 0 && (
                        <span className="absolute top-0 right-0 h-4 w-4 bg-red-500 rounded-full text-xs text-white flex items-center justify-center">
                    {incomingFriendRequests.length}
                  </span>
                    )}
                    <span className="sr-only">Notifications</span>
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-80">
                <div className="grid gap-4">
                    <div className="space-y-2">
                        <h4 className="font-medium leading-none">Friend Requests</h4>
                        <p className="text-sm text-muted-foreground">
                            Manage your incoming friend requests
                        </p>
                    </div>
                    <div className="grid gap-2">
                        {incomingFriendRequests.length === 0 ? (
                            <p className="text-sm text-muted-foreground">No pending requests</p>
                        ) : (
                            incomingFriendRequests.map((user) => (
                                <div key={user.id} className="flex items-center gap-4">
                                    <Link to={`/u/${user.username}`}>
                                        <Avatar className="h-10">
                                            <AvatarImage src={user.images[0].url} alt={user.displayName}/>
                                            <AvatarFallback>{user.displayName}</AvatarFallback>
                                        </Avatar>
                                    </Link>
                                    <div className="grid gap-0.5 text-sm">
                                        <h5 className="font-medium">{user.displayName}</h5>
                                        <p className="text-muted-foreground">Wants to be your friend</p>
                                    </div>
                                    <div className="ml-auto flex gap-2">
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={async () => {
                                                await acceptFriendRequest(user.id)
                                                invalidateAutoQuery(queryClient, getIncomingFriendRequests, 3)
                                            }}
                                        >
                                            <CheckIcon className="h-4 w-4 text-green-500"/>
                                        </Button>
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={async () => {
                                                await rejectFriendRequest(user.id)
                                                invalidateAutoQuery(queryClient, getIncomingFriendRequests, 3)
                                            }}
                                        >
                                            <XIcon className="h-4 w-4 text-red-500"/>
                                        </Button>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    )
}

function SearchBar() {
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault()
        const query = e.target.elements[0].value
        if (query) {
            navigate(`/search?q=${encodeURIComponent(query)}`)
        }
    }

    return (
        <form
            className="flex flex-row items-center flex-grow p-2 h-full w-full max-w-sm rounded-lg bg-background text-foreground"
            onSubmit={handleSubmit}>
            <SearchIcon className="h-fit text-gray-500"/>
            <Input
                type="search"
                placeholder="Search artists, tracks, albums..."
                className="border-0 placeholder-gray-500 text-sm"
            />
        </form>
)
}

export function DesktopHeader() {
    let {hasToken} = useStoreWithEqualityFn(tokenStore)

    return (
        <header className="flex items-center w-full justify-between h-16 py-2 px-4 gap-2 bg-card border border-background text-card-foreground shadow-sm">
            <Nav/>
            <div className="flex flex-row items-center justify-end gap-2 w-full h-full">
                <SearchBar/>
                {hasToken ? <FriendsButton/> : null}
                {hasToken ? <UserInfo/> : null}
                {!hasToken ? <LoginButton/> : null}
            </div>
        </header>
    )
}
