import {useAutoQuery} from "../../utils"
import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "../ui/card"
import {MoonStar} from "lucide-react"
import {SongCard} from "../SongCard"
import {getOneHitWonders} from "../../db/DbService"
import {DynamicGrid} from "../DynamicGrid";

export function OneHitWondersCard() {
    const {data: groups} = useAutoQuery(getOneHitWonders, {limit: 100})

    return (
        <Card>
            <CardHeader className="flex flex-col gap-1">
                <CardTitle className="flex items-center gap-2">
                    <MoonStar className="h-6 w-6"/>
                    One-Hit Wonders
                </CardTitle>
                <CardDescription>Artists who made a lasting impact with a single hit.</CardDescription>
            </CardHeader>
            <CardContent>
                <DynamicGrid className="gap-2" targetWidthInRem={10}>
                    {groups?.map(group => <SongCard songId={group.entityId} key={group.entityId}/>)}
                </DynamicGrid>
            </CardContent>
        </Card>
    )
}
