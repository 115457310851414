import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "../components/ui/card"
import {AlertCircleIcon, FileIcon, UploadIcon} from "lucide-react"
import * as PropTypes from "prop-types"
import {Input} from "../components/ui/input"
import {Button} from "../components/ui/button"
import {Link, useNavigate} from "react-router-dom"
import {useState} from "react"
import {getSpotifyBackfillImport, uploadListeningHistory} from "../api/ApiClient"
import {sleep} from "../utils"
import {Duration} from "luxon"

function Label(props) {
    return null
}

Label.propTypes = {
    htmlFor: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
}

export function SpotifyDataUploadPage() {
    const [selectedFile, setSelectedFile] = useState(null)
    const navigate = useNavigate()

    const upload = async () => {
        let backfillImport = await uploadListeningHistory(selectedFile)

        while (backfillImport.state === "PROCESSING") {
            backfillImport = await getSpotifyBackfillImport(backfillImport.id)
            await sleep(Duration.fromObject({seconds: 1}))
        }

        navigate("/home")
    }

    return (
        <Card className="w-full max-w-2xl flex flex-col">
            <CardHeader>
                <CardTitle className="text-2xl">Upload Your Spotify Listening History</CardTitle>
                <CardDescription>
                    Get personalized insights by uploading your Spotify data export package.
                </CardDescription>
            </CardHeader>
            <CardContent className="flex flex-col gap-4">
                <h3 className="text-lg font-semibold">How to get your Spotify data:</h3>
                <ol className="list-decimal list-inside space-y-1 text-sm">
                    <li>Go to your Spotify account page</li>
                    <li>Click on "Privacy settings"</li>
                    <li>Scroll to "Download your data" and request a copy</li>
                    <li>Wait for the email from Spotify (it may take up to 30 days)</li>
                    <li>Download the ZIP file containing your data</li>
                </ol>
                <div className="flex flex-col items-center gap-2 border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg p-6 text-center cursor-pointer" onClick={() => document.getElementById("file-upload")?.click()}>
                    <UploadIcon className="mx-auto h-12 w-12 text-gray-400"/>
                    <Label htmlFor="file-upload" className="mt-2 block text-sm font-semibold text-gray-900 dark:text-gray-100">
                        Upload your Spotify data ZIP file
                    </Label>
                    <p className="text-xs text-gray-500 dark:text-gray-400">ZIP file up to 50MB</p>
                    <Input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        accept=".zip"
                        className="sr-only"
                        onChange={event => setSelectedFile(event.target.files[0])}
                    />
                    {
                        selectedFile !== null
                            ? <div className="flex flex-row gap-2 items-center">
                                <FileIcon className="w-5 h-5"/>{selectedFile.name}</div>
                            : <span>Select a file</span>
                    }
                </div>
                <div className="flex gap-2 items-center text-sm text-gray-700 dark:text-gray-300">
                </div>
                <Button className="w-full bg-gradient-to-r from-purple-500 to-indigo-700 text-white" disabled={selectedFile == null} onClick={upload}>
                    Upload and Analyze
                </Button>
                <div className="flex items-center space-x-2 text-sm">
                    <AlertCircleIcon className="h-5 w-5 text-yellow-500 mt-0.5 flex-shrink-0"/>
                    <p className="text-gray-600 dark:text-gray-400">
                        By uploading your Spotify data, you agree to our{" "}
                        <Link to="#" className="text-indigo-700 hover:underline">Terms of Service</Link> and{" "}
                        <Link to="#" className="text-indigo-700 hover:underline">Privacy Policy</Link>.
                        We only analyze your listening history and do not store any personal information.
                    </p>
                </div>
            </CardContent>
        </Card>
    )
}