import {useSong} from "../stores/SongStore"
import {Link} from "react-router-dom"
import {formatTrackDuration} from "../utils"
import React from "react"

export function SongCard({songId}) {
    const {data: song, isPending} = useSong(songId)

    if(isPending) {
        return
    }

    return (
        <Link to={`/song/${song.id}`} key={song.id} className="flex flex-col items-center gap-1 text-center">
            <div className="relative">
                <img
                    src={song.images[0]?.url}
                    alt={song.name}
                    className="w-full aspect-square object-cover rounded-md shadow-lg"
                />
                <div className="absolute bottom-2 right-2 bg-gray-800 text-white px-2 py-1 rounded-full text-xs font-semibold">
                    {formatTrackDuration(song.durationMs)}
                </div>
            </div>
            <div className="flex flex-col">
                <h4 className="font-semibold text-sm line-clamp-1 text-foreground">{song.name}</h4>
                <p className="text-xs line-clamp-1 text-muted-foreground">{song.artistCredit.segments.map(segment => segment.text).join("")}</p>
            </div>
        </Link>
    )
}