import {Link} from "react-router-dom"
import {Button} from "./ui/button"

export function LoginButton() {
    return (
        <Link to="/login">
            <Button className="text-white bg-accent">
                Login
            </Button>
        </Link>
    )
}