import { create, keyResolver, windowedFiniteBatchScheduler } from "@yornaath/batshit"
import { getAlbums } from "../api/ApiClient"
import { useQueries, useQuery } from "@tanstack/react-query"

const albums = create({
    fetcher: getAlbums,
    resolver: keyResolver("id"),
    scheduler: windowedFiniteBatchScheduler({
        windowMs: 100,
        maxBatchSize: 100,
    }),
})

export function useAlbum(id) {
    return useQuery(getQuery(id))
}

export function useAlbums(ids) {
    return useQueries({
        queries: ids.map(getQuery),
    })
}

function getQuery(id) {
    return {
        queryKey: ["albums", Number(id)],
        queryFn: async () => albums.fetch(id),
        throwOnError: true,
    }
}