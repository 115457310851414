import {Link} from "react-router-dom"
import {BarChart3, ClockIcon} from "lucide-react"
import {formatCount, formatListenDuration, getAlbumName, getFlattenedArtistCredit} from "../utils"
import React from "react"
import {Badge} from "./ui/badge"
import {useAlbum} from "../stores/AlbumStore"

export function AlbumWithStatsCard({group}) {
    const {data: album, isPending} = useAlbum(group.entityId)

    if (isPending) {
        return
    }

    const name = getAlbumName(album)
    const artistCreditName = getFlattenedArtistCredit(album.artistCredit)

    return (
        <Link to={`/album/${album.id}`} className="flex flex-col rounded-md overflow-hidden drop-shadow-lg">
            <img src={album.images[0]?.url} alt={name} className="aspect-square"/>
            <div className="flex flex-col gap-1 p-2 border border-background">
                <span className="text-md font-semibold text-nowrap truncate">{name}</span>
                <span className="text-sm text-nowrap truncate">{artistCreditName}</span>
                <Badge variant="secondary">
                    <BarChart3 className="w-3 h-3"/>
                    <span className="text-xs">{formatCount(group.count)} plays</span>
                </Badge>
                <Badge variant="secondary">
                    <ClockIcon className="w-3 h-3"/>
                    <span className="text-xs">{formatListenDuration(group.durationMs)}</span>
                </Badge>
            </div>
        </Link>
    )
}