import { create, keyResolver, windowedFiniteBatchScheduler } from "@yornaath/batshit"
import { getSongs } from "../api/ApiClient"
import { useQueries, useQuery } from "@tanstack/react-query"

const songs = create({
    fetcher: getSongs,
    resolver: keyResolver("id"),
    scheduler: windowedFiniteBatchScheduler({
        windowMs: 100,
        maxBatchSize: 100,
    }),
})

export function useSong(id) {
    return useQuery(getQuery(id))
}

export function useSongs(ids) {
    return useQueries({
        queries: ids.map(getQuery),
    })
}

function getQuery(id) {
    return {
        queryKey: ["songs", Number(id)],
        queryFn: async () => songs.fetch(id),
        throwOnError: true,
        enabled: id !== null && id !== undefined
    }
}