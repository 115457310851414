import React from "react"
import {MobilePlayerBar} from "./PlayerBar"
import {MobileNav} from "./MobileNav"
import {useMeasure} from "react-use";

export function MobileLayout({children}) {
    const [ref, {height: bottomBarHeight}] = useMeasure()

    return (
        <>
            <main className="flex flex-col bg-background w-full min-h-[100dvh] max-w-6xl p-2">
                {children}
                <div style={{height: bottomBarHeight}}/>
            </main>
            <div className="fixed bottom-0 left-0 right-0 w-full flex flex-col gap-1" ref={ref}>
                <MobilePlayerBar/>
                <MobileNav/>
            </div>
        </>
    );
}