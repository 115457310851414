import React from "react"
import {Link, useSearchParams} from "react-router-dom"
import {
    cn,
    epoch,
    formatCount,
    formatListenDuration,
    getArtistCreditAsString,
    roundedNow,
    useAutoQuery,
} from "../utils"
import {useArtist} from "../stores/ArtistStore"
import {Avatar, AvatarFallback, AvatarImage} from "../components/ui/avatar"
import {Pagination} from "../components/Pagination"
import {LoadingPage} from "./LoadingPage"
import {getRankings} from "../db/DbService"
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../components/ui/select"
import {DateTime} from "luxon"
import {Separator} from "../components/ui/separator"
import {useAlbum} from "../stores/AlbumStore"
import {useSong} from "../stores/SongStore"
import {Textfit} from "react-textfit"
import {Badge} from "../components/ui/badge"
import {Skeleton} from "../components/ui/skeleton";

export function TopPage() {
    const [searchParams, setSearchParams] = useSearchParams()

    const selectedEntity = searchParams.get("entity") || "artist"
    const selectedTimeFrame = searchParams.get("timeFrame") || "allTime"
    const [start, end] = getStartAndEnd(selectedTimeFrame)

    const { data: groups, isPending } = useAutoQuery(getRankings, {
        entity: selectedEntity,
        start,
        end
    })

    const setQueryParams = (newParams) => {
        setSearchParams(prev => {
            for(const key in newParams) {
                const value = newParams[key]
                if(value !== null) {
                    prev.set(key, value)
                } else {
                    prev.delete(key)
                }
            }
            return prev
        })
    }

    if (isPending) {
        return <LoadingPage/>
    }

    const pageSize = 100
    const page = parseInt(searchParams.get("page") ?? "1", 10)
    const pageCount = Math.ceil(groups.length / pageSize)
    const pagedGroups = groups.slice((page - 1) * 100, page * 100)

    if(page > pageCount) {
        setQueryParams({
            "page": 1
        })
    }

    return (
        <div className="flex flex-col items-center gap-2 w-full">
            <div className="flex flex-row items-center gap-1.5 text-2xl font-bold text-foreground">
                <span className="line-clamp-1">Your top</span>
                <Select value={selectedEntity} onValueChange={value => setQueryParams({entity: value, page: 1})}>
                    <SelectTrigger>
                        <SelectValue/>
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="artist">artists</SelectItem>
                        <SelectItem value="album">albums</SelectItem>
                        <SelectItem value="song">songs</SelectItem>
                    </SelectContent>
                </Select>
                <span>of</span>
                <Select value={selectedTimeFrame} onValueChange={value => setQueryParams({timeFrame: value, page: 1})}>
                    <SelectTrigger>
                        <SelectValue/>
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="allTime">all time</SelectItem>
                        <SelectItem value="thisWeek">this week</SelectItem>
                        <SelectItem value="thisYear">this year</SelectItem>
                        <SelectItem value="last30Days">last 30 days</SelectItem>
                        <SelectItem value="year2024">2024</SelectItem>
                    </SelectContent>
                </Select>
            </div>
            <Separator/>
            <div className="flex flex-col gap-1.5 w-full">
                {pagedGroups.map(group => <Row entity={selectedEntity} group={group} key={group.entityId}/>)}
            </div>
            <Pagination page={page} pageCount={pageCount} />
        </div>
    )
}

function Row({group, entity}) {
    switch(entity) {
        case "artist": return <ArtistRow group={group}/>
        case "album": return <AlbumRow group={group}/>
        case "song": return <Song group={group}/>
        default: return null
    }
}

function Row0({group, content}) {
    return (
        <div className="flex flex-row gap-2 w-full h-[3.5rem] items-center bg-card rounded-md p-1">
            <div className="w-[2rem] flex-shrink-0">
                <Rank rank={group.rank}/>
            </div>
            <div className="flex-1 h-full min-w-0 gradient-mask-r-80 overflow-hidden">
                {content}
            </div>
            <div className="flex flex-col items-end flex-shrink-0">
                <span className="text-[0.75rem] text-muted-foreground">{formatCount(group.count)} listens</span>
                <span className="text-[0.75rem] text-muted-foreground">{formatListenDuration(group.durationMs)}</span>
            </div>
        </div>
    )
}

function SkeletonContent({imgClassName}) {
    return (
        <div className="flex flex-row gap-2 items-center h-full min-w-0">
            <Skeleton className={cn("h-full aspect-square", imgClassName)}/>
            <div className="flex flex-col items-start justify-center h-full gap-1.5">
                <Skeleton className="h-5 w-32"/>
                <Skeleton className="h-4 w-24"/>
            </div>
        </div>
    )
}

function ArtistContent({ group }) {
    const { data: artist, isPending } = useArtist(group.entityId)

    if (isPending) {
        return <SkeletonContent imgClassName="rounded-full"/>
    }

    return (
        <Link className="flex flex-row gap-2 items-center h-full w-fit" to={`/artist/${group.entityId}`}>
            <Avatar className="h-full aspect-square">
                <AvatarImage src={artist.images[0]?.url}/>
                <AvatarFallback>{artist.name[0]}</AvatarFallback>
            </Avatar>
            <div className="flex flex-col items-start justify-center min-w-0 max-w-full">
                <span className="text-[1rem] text-foreground font-semibold text-nowrap">{artist.name}</span>
                <div className="flex flex-row gap-1 overflow-hidden max-w-full">
                    {artist.genres.map(genre => <GenreBadge genre={genre} key={genre.id}/>)}
                </div>
            </div>
        </Link>
    )
}

function ArtistRow({ group }) {
    return <Row0
        group={group}
        content={<ArtistContent group={group}/>}
    />
}

function GenreBadge({genre}) {
    return (
        <Badge className="line-clamp-1 flex-shrink-0 h-fit" variant="outline">
            {genre.name}
        </Badge>
    )
}

function AlbumContent({ group }) {
    const { data: album, isPending } = useAlbum(group.entityId)

    if (isPending) {
        return <SkeletonContent imgClassName="rounded-md"/>
    }

    return (
        <Link className="flex flex-row gap-2 items-center h-full min-w-0" to={`/album/${group.entityId}`}>
            <img
                className="h-12 w-12 rounded-md flex-shrink-0 object-cover"
                src={album.images[0].url}
                alt={album.name}
            />
            <div className="flex flex-col justify-center min-w-0">
                <span className="text-[1rem] text-foreground font-semibold text-nowrap">{album.name}</span>
                <span className="text-[0.75rem] text-muted-foreground text-nowrap">{getArtistCreditAsString(album.artistCredit)}</span>
            </div>
        </Link>
    )
}

function AlbumRow({ group }) {
    return <Row0
        group={group}
        content={<AlbumContent group={group}/>}
    />
}

function Song({group}) {
    return <Row0
        group={group}
        content={<SongContent group={group}/>}
    />
}

function SongContent({group}) {
    const { data: song, isPending } = useSong(group.entityId)

    if (isPending) {
        return <SkeletonContent imgClassName="rounded-md"/>
    }

    return (
        <Link className="flex flex-row gap-2 items-center h-full min-w-0" to={`/song/${group.entityId}`}>
            <img
                className="h-full aspect-square rounded-md flex-shrink-0"
                src={song.images[0].url}
                alt={song.name}
            />
            <div className="flex flex-col justify-center min-w-0">
                <span className="text-[1rem] text-foreground font-semibold text-nowrap">{song.name}</span>
                <span className="text-[0.75rem] text-muted-foreground text-nowrap">{getArtistCreditAsString(song.artistCredit)}</span>
            </div>
        </Link>
    )
}

function Rank({rank}) {
    return (
        <div className="text-foreground">
            <Textfit mode="single" className="text-right" max={30} throttle={1000}>
                #<b>{rank}</b>
            </Textfit>
        </div>
    )
}

function getStartAndEnd(timeframe) {
    if(timeframe.startsWith("year")) {
        const year = parseFloat(timeframe.substring(4))
        return [
            DateTime.fromObject({ year }).startOf("year"),
            DateTime.fromObject({ year }).endOf("year")
        ]
    }

    switch(timeframe) {
        case "allTime": return [epoch(), roundedNow()]
        case "thisWeek": return [roundedNow().startOf("week"), roundedNow()]
        case "thisYear": return [roundedNow().startOf("year"), roundedNow()]
        case "last30Days": return [roundedNow().minus({days: 30}), roundedNow()]
        default: throw new Error(`Unknown timeframe "${timeframe}"`)
    }
}