import {Link} from "react-router-dom"
import React from "react"
import {cn} from "../utils"

export function ArtistCredit({artistCredit, className}) {
    return (
        <div className={cn("flex flex-row flex-wrap text-xl", className)}>
            {artistCredit.segments.map((segment, index) => <Segment segment={segment} key={index}/>)}
        </div>
    )
}

export function Segment({ segment }) {
    if (segment.artistId) {
        return <ArtistSegment segment={segment}/>
    } else {
        return <TextSegment segment={segment}/>
    }
}

function ArtistSegment({ segment }) {
    return (
        <Link to={`/artist/${segment.artistId}`} className="hover:opacity-75 transition-colors flex flex-row items-center">
            <span className="line-clamp-1">{segment.text}</span>
        </Link>
    )
}

function TextSegment({ segment }) {
    return (
        <span className="line-clamp-1 whitespace-pre">{segment.text}</span>
    )
}