import {roundedNow, useAutoQuery, useIsMobile} from "../../utils"
import {getTopArtists, getTopSongs} from "../../db/DbService"
import {useMeasure} from "react-use"
import {ArtistBubbles} from "../ArtistBubbles"
import {AlbumBubbles} from "../AlbumBubbles"
import {getTopAlbums} from "../../db/DbClient"
import {SongBubbles} from "../SongBubbles"
import {Link} from "react-router-dom"
import {CalendarRange, ChevronRight} from "lucide-react"

export function SummaryBar() {
    const [ref, { width }] = useMeasure()
    const isMobile = useIsMobile()

    if(width === 0) {
        return <div className="w-full" ref={ref}></div>
    }

    const multiplier = isMobile ? 2.8 : 1
    const size = width * multiplier

    return (
        <div className="flex flex-col gap-2 w-full" ref={ref}>
            <div className="flex items-center gap-2">
                <CalendarRange className="w-5 h-5 text-muted-foreground" />
                <h2 className="text-lg font-semibold text-foreground">Past 30 Days</h2>
            </div>
            <div className="w-full overflow-x-auto">
                <div className="grid grid-cols-3 gap-6" style={{width: `${size}px`}}>
                    <TopArtists/>
                    <TopAlbums/>
                    <TopSongs/>
                </div>
            </div>
        </div>
    )
}

function TopArtists() {
    const isMobile = useIsMobile()
    const {data: groups} = useAutoQuery(getTopArtists, {
        start: roundedNow().minus({days: 30}),
        end: roundedNow(),
        limit: isMobile ? 30 : 50,
    })

    return <Card
        bubbles={<ArtistBubbles groups={groups || []}/>}
        text="Artists"
        to="/top?entity=artist&timeFrame=last30Days"
    />
}

function TopAlbums() {
    const isMobile = useIsMobile()
    const {data: groups} = useAutoQuery(getTopAlbums, {
        start: roundedNow().minus({days: 30}),
        end: roundedNow(),
        limit: isMobile ? 30 : 50,
    })

    return <Card
        bubbles={<AlbumBubbles groups={groups || []}/>}
        text="Albums"
        to="/top?entity=album&timeFrame=last30Days"
    />
}

function TopSongs() {
    const isMobile = useIsMobile()
    const {data: groups} = useAutoQuery(getTopSongs, {
        start: roundedNow().minus({days: 30}),
        end: roundedNow(),
        limit: isMobile ? 30 : 50,
    })

    return <Card
        bubbles={<SongBubbles groups={groups || []}/>}
        text="Songs"
        to="/top?entity=song&timeFrame=last30Days"
    />
}

function Card({bubbles, text, to}) {
    return (
        <div className="flex flex-col items-center gap-2 w-full">
            <div className="w-full h-[40vh] rounded-lg shadow-sm overflow-hidden">
                {bubbles}
            </div>
            <Link to={to} className="flex flex-row items-center gap-1 text-foreground">
                <h3 className="text-md lg:text-xl font-medium">{text}</h3>
                <ChevronRight/>
            </Link>
        </div>

    )
}