import {LandingPage} from "./pages/LandingPage"
import {AboutPage} from "./pages/AboutPage"
import {BrowserRouter as Router, Navigate, Outlet, Route, Routes, useNavigate} from "react-router-dom"
import {ArtistPage} from "./pages/artist/ArtistPage"
import {AuthCallbackSpotifyPage} from "./pages/AuthCallbackSpotifyPage"
import {JourneyPage} from "./pages/JourneyPage"
import {Layout} from "./components/Layout"
import {AlbumPage} from "./pages/AlbumPage"
import {SongPage} from "./pages/SongPage"
import {LoginPage} from "./pages/LoginPage"
import {SpotifyDataUploadPage} from "./pages/SpotifyDataUploadPage"
import {GenrePage} from "./pages/GenrePage"
import {SearchPage} from "./pages/SearchPage"
import {UserPage} from "./pages/UserPage"
import {YearPage} from "./pages/YearPage"
import {databaseStore} from "./stores/DatabaseStore"
import {useStoreWithEqualityFn} from "zustand/traditional"
import {DatabaseLoadingPage} from "./pages/DatabaseLoadingPage"
import {tokenStore} from "./stores/ApiTokenStore"
import {TopPage} from "./pages/TopPage"
import {HomePage} from "./pages/HomePage"
import {SettingsPage} from "./pages/SettingsPage"
import {useEffect} from "react"

export function App() {
    const { hasToken } = useStoreWithEqualityFn(tokenStore);
    const { loading: databaseLoading } = useStoreWithEqualityFn(databaseStore);

    return (
        <Router>
            <Layout>
                {hasToken && databaseLoading ? (
                    <DatabaseLoadingPage />
                ) : (
                    <Routes>
                        <Route path="/" element={
                            hasToken
                            ? <Navigate to="/home"/>
                            : <LandingPage />
                        }/>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/auth/callback/spotify" element={<AuthCallbackSpotifyPage />} />

                        { /* Logged in routes */ }
                        <Route element={<LoggedInPage/>}>
                            <Route path="/home" element={<HomePage />} />
                            <Route path="/dashboard" element={<Navigate to="/home"/>} />
                            <Route path="/u/:username" element={<UserPage />} />
                            <Route path="/artist/:artistId" element={<ArtistPage />} />
                            <Route path="/album/:albumId" element={<AlbumPage />} />
                            <Route path="/song/:songId" element={<SongPage />} />
                            <Route path="/genre/:genreId" element={<GenrePage />} />
                            <Route path="/year/:year" element={<YearPage />} />
                            <Route path="/journey" element={<JourneyPage />} />
                            <Route path="/top" element={<TopPage />} />
                            <Route path="/search" element={<SearchPage />} />
                            <Route path="/spotify-data-upload" element={<SpotifyDataUploadPage />} />
                            <Route path="/settings" element={<SettingsPage/>}/>
                        </Route>
                    </Routes>
                )}
            </Layout>
        </Router>
    );
}

function LoggedInPage() {
    const { hasToken } = useStoreWithEqualityFn(tokenStore);
    const navigate = useNavigate()

    useEffect(() => {
        if(!hasToken) {
            navigate("/")
        }
    }, [hasToken, navigate])

    return hasToken ? <Outlet/> : null
}
