import * as db from "./DbClient"
import {
    calculateAlbumRelevances,
    calculateArtistRelevances,
    calculateArtistStreaks,
    calculateSongRelevances,
    getAlbumGraphListenGroups,
    getAlbumRelevance as getAlbumRelevanceDb,
    getArtistRelevance as getArtistRelevanceDb,
    getArtistsGraphListenGroups,
    getDiscoveredArtistCountByYear,
    getSongGraphListenGroups,
    getSongRelevance as getSongRelevanceDb,
    getSummary,
    getTopArtistsForGenre,
    getTopArtistsForOther,
    getTopNAlbumsByYears,
    getTopNArtistsByYears,
    getTopNewlyDiscoveredArtists as getTopNewlyDiscoveredArtistsDb,
    getTopNGenresByYears,
    getTopNHoursByYear,
    getTopNSongByYears,
    getTopSongsForGenre,
    getTopSongsForOther,
    getTopYears
} from "./DbClient"
import {DateTime} from "luxon"
import {epoch, groupBy, pickN, toMap} from "../utils"
import {StatCalculator} from "./StatCalculator"
import {search} from "../api/ApiClient"

const artistRelevanceCalculator = new StatCalculator(calculateArtistRelevances)
const albumRelevanceCalculator = new StatCalculator(calculateAlbumRelevances)
const songRelevanceCalculator = new StatCalculator(calculateSongRelevances)
const artistStreakCalculator = new StatCalculator(calculateArtistStreaks)

export async function getTopSongs({artistId, albumId, genreId, start, end, limit}) {
    if (genreId != null) {
        return getTopSongsForGenre({genreId, start, end, limit})
    } else {
        return getTopSongsForOther({artistId, albumId, start, end, limit})
    }
}

export async function getTopArtists({genreId, start, end, limit}) {
    if (genreId != null) {
        return getTopArtistsForGenre({genreId, start, end, limit})
    } else {
        return getTopArtistsForOther({start, end, limit})
    }
}

export async function getTopNewlyDiscoveredArtists({discoveredAfter, limit}) {
    return getTopNewlyDiscoveredArtistsDb({discoveredAfter, limit})
}

export async function getNewlyDiscoveredSongs({limit}) {
    return db.getTopNewlyDiscoveredSongs({limit})
}

export async function getYears() {
    const topYearsPromise = getTopYears()
    const topArtistsPromise = getTopNArtistsByYears({n : 3})
    const topSongsPromise = getTopNSongByYears({n: 3})
    const topAlbumsPromise = getTopNAlbumsByYears({n: 3})
    const topGenresPromise = getTopNGenresByYears({n: 15})
    const topHoursPromise = getTopNHoursByYear({n: 1})
    const discoveredArtistsCountPromise = getDiscoveredArtistCountByYear()

    const topYears = await topYearsPromise
    const topArtistsByYear = groupBy(await topArtistsPromise, group => group.partition)
    const topSongsByYear = groupBy(await topSongsPromise, group => group.partition)
    const topAlbumsByYear = groupBy(await topAlbumsPromise, group => group.partition)
    const topGenresByYear = groupBy(await topGenresPromise, group => group.partition)
    const topHourByYear = toMap(await topHoursPromise, group => group.partition)
    const discoveredArtistsCountByYear = toMap(await discoveredArtistsCountPromise, group => group.year)

    return topYears
        .filter(group => group.count >= 10)
        .sort((a, b) => b.entityId - a.entityId)
        .map(group => {
            group.topArtists = topArtistsByYear.get(group.entityId) || []
            group.topSongs = topSongsByYear.get(group.entityId) || []
            group.topAlbums = topAlbumsByYear.get(group.entityId) || []
            group.topGenres = topGenresByYear.get(group.entityId) || []
            group.topHour = topHourByYear.get(group.entityId)
            group.discoveredArtistCount = discoveredArtistsCountByYear.get(group.entityId)?.count || 0

            return group
        })
}

export async function searchWithReordering(query) {
    if(!query) {
        return {
            artists: [],
            albums: [],
            songs: []
        }
    }

    const results = await search(query)

    for (const artist of results.artists) {
        const relevance = await getArtistRelevance(artist.id)
        artist.score *= relevance + 1
    }
    results.artists.sort((a, b) => b.score - a.score)

    for (const album of results.albums) {
        const relevance = await getAlbumRelevance(album.id)
        album.score *= relevance + 1
    }
    results.albums.sort((a, b) => b.score - a.score)

    for (const song of results.songs) {
        const relevance = await getSongRelevance(song.id)
        song.score *= relevance + 1
    }
    results.songs.sort((a, b) => b.score - a.score)
    
    return results
}

async function getArtistRelevance(artistId) {
    await artistRelevanceCalculator.ensureCalculated()
    return await getArtistRelevanceDb(artistId) || 0
}

async function getAlbumRelevance(albumId) {
    await albumRelevanceCalculator.ensureCalculated()
    return await getAlbumRelevanceDb(albumId) || 0
}

async function getSongRelevance(songId) {
    await songRelevanceCalculator.ensureCalculated()
    return await getSongRelevanceDb(songId) || 0
}

export async function getArtistRankingsWithMovement() {
    const threshold = await db.getTimestampThreshold(0.9)
    return await db.getArtistRankingsWithMovement(threshold)
}

export async function getRankings({entity, start, end}) {
    switch(entity) {
        case "artist": return await db.getRankedArtists({start, end})
        default: {
            let table = entity + "_listen";
            return await db.getRankings({table, start, end})
        }
    }
}

export async function getOneHitWonders({limit}) {
    const groups = await db.getOneHitWonders({limit: 25})
    return pickN(groups, limit)
}

export async function getBlastFromThePastSongs({limit}) {
    const threshold = await db.getTimestampThreshold(0.2)
    const groups = await getTopSongs({ start: epoch(), end: threshold, limit: 100 })
    return pickN(groups, limit)
}

export async function getTopDecades({start, end, limit, limitPerDecade}) {
    const decadeGroups = await db.getTopDecades({start, end, limit})
    const songGroups = await db.getTopSongsPerDecade({start, end, limitPerDecade})
    const songGroupsByDecade = groupBy(songGroups, group => group.decade)
    for(const decadeGroup of decadeGroups) {
        decadeGroup.songs = songGroupsByDecade.get(decadeGroup.entityId)
    }
    return decadeGroups
}

export async function getArtistsGraph({start, end, distinctCount}) {
    const graphSettings = await getGraphSettings({start, end})
    const listenGroups = await getArtistsGraphListenGroups({
        start: graphSettings.start,
        end: graphSettings.end,
        datePart: graphSettings.interval,
        distinctCount,
    })
    return buildGraph(listenGroups, graphSettings)
}

export async function getAlbumsGraph({artistId, start, end, distinctCount}) {
    const graphSettings = await getGraphSettings({start, end, artistId})
    const listenGroups = await getAlbumGraphListenGroups({
        artistId,
        start: graphSettings.start,
        end: graphSettings.end,
        datePart: graphSettings.interval,
        distinctCount,
    })
    return buildGraph(listenGroups, graphSettings)
}

export async function getSongsGraph({artistId, albumId, start, end, distinctCount}) {
    const graphSettings = await getGraphSettings({start, end, artistId, albumId})
    const listenGroups = await getSongGraphListenGroups({
        artistId,
        albumId,
        start: graphSettings.start,
        end: graphSettings.end,
        datePart: graphSettings.interval,
        distinctCount,
    })
    return buildGraph(listenGroups, graphSettings)
}

export async function getLongestArtistStreak({artistId}) {
    await artistStreakCalculator.ensureCalculated()
    return await db.getLongestArtistStreak({artistId})
}

export async function getLongestActiveArtistStreaks({limit}) {
    await artistStreakCalculator.ensureCalculated()
    return await db.getLongestActiveArtistStreaks({limit})
}

export async function getActiveArtistStreak({artistId}) {
    await artistStreakCalculator.ensureCalculated()
    return await db.getActiveArtistStreak({artistId})
}

async function getGraphSettings({start, end, artistId, albumId}) {
    const summary = await getSummary({start, end, artistId, albumId})

    start = summary.firstListen ? DateTime.fromMillis(summary.firstListen) : start
    end = summary.lastListen ? DateTime.fromMillis(summary.lastListen) : end

    const interval = getInterval(start, end)

    start = truncate(start, interval)
    end = truncate(end, interval)

    return {start, end, interval}
}

function getInterval(start, end) {
    const duration = end.diff(start, ["days"])

    // A little more than a year to force year periods to be weekly
    if (duration.days >= 7 * 53) {
        return "MONTHS"
        // A little more than a month to force month periods to be weekly
    } else if (duration.days >= 32) {
        return "WEEKS"
    } else {
        return "DAYS"
    }
}

function truncate(dateTime, interval) {
    switch (interval) {
        case "MONTHS":
            return dateTime.startOf("month").startOf("day")
        case "WEEKS":
            return dateTime.startOf("week").startOf("day")
        case "DAYS":
            return dateTime.startOf("day")
        default:
            throw new Error("Unsupported operation")
    }
}

function buildGraph(listenGroups, graphSettings) {
    const listenGroupsByKey = {}
    const entityIds = new Set()

    for (const listenGroup of listenGroups) {
        entityIds.add(listenGroup.entityId)
        listenGroupsByKey[[listenGroup.entityId, listenGroup.partition]] = listenGroup
    }

    const ticks = buildTicks(graphSettings)

    const lines = [...entityIds].map(entityId => {
        const points = ticks.map(tick => {
            const key = [entityId, tick.toMillis()]
            const listenGroup = listenGroupsByKey[key]
            let point
            if (listenGroup != null) {
                point = {time: tick.toMillis(), count: Number(listenGroup.count), durationMs: listenGroup.durationMs}
            } else {
                point = {time: tick.toMillis(), count: 0, durationMs: 0}
            }
            return point
        })
        return {entityId: entityId, points}
    })

    return {lines, graphSettings}
}

function buildTicks(graphSettings) {
    const {start, end, interval} = graphSettings

    const result = []
    let current = start

    while (current < end) {
        result.push(current)
        current = current.plus({[interval]: 1})
    }

    return result
}