import axios from "axios"
import { clearToken, tokenStore } from "../stores/ApiTokenStore"

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
})

httpClient.interceptors.request.use(
    request => {
        request.headers.Authorization = tokenStore.getState().token
        return request
    },
    error => {
        return Promise.reject(error)
    },
)

httpClient.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error?.response?.status === 401) {
            clearToken()
        }
        return Promise.reject(error)
    },
)

export async function getCurrentUser() {
    let response = await httpClient.get("/users/@me")
    return response.data
}

export async function getUserByUsername(username) {
    let response = await httpClient.get(`/users?username=${username}`)
    return response.data[0]
}

export async function getArtists(ids) {
    let response = await httpClient.get(`/artists`, {
        params: {
            ids: ids.join(","),
        },
    })
    return response.data
}

export async function getAlbums(ids) {
    let response = await httpClient.get(`/albums`, {
        params: {
            ids: ids.join(","),
        },
    })
    return response.data
}

export async function getSongs(ids) {
    let response = await httpClient.get(`songs`, {
        params: {
            ids: ids.join(","),
        },
    })
    return response.data
}

export async function getGenres(ids) {
    let response = await httpClient.get(`/genres`, {
        params: {
            ids: ids.join(","),
        },
    })
    return response.data
}

export async function getSongAlbums(id) {
    let response = await httpClient.get(`/songs/${id}/albums`)
    return response.data
}

export async function getArtistAlbums(id) {
    let response = await httpClient.get(`/artists/${id}/albums`)
    return response.data
}

export async function startSpotifyLogin() {
    let response = await httpClient.post("/login/spotify/start")
    return response.data
}

export async function finishSpotifyLogin(code) {
    let response = await httpClient.post("/login/spotify/finish", {
        code: code,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    return response.data
}

export async function uploadListeningHistory(file) {
    let formData = new FormData()
    formData.append("file", file)
    let response = await httpClient.post("/spotify/backfillImports", formData)
    return response.data
}

export async function getSpotifyBackfillImport(id) {
    let response = await httpClient.get(`/spotify/backfillImports/${id}`)
    return response.data
}

export async function getSpotifyBackfillImports() {
    let response = await httpClient.get("/spotify/backfillImports")
    return response.data
}

export async function search(query) {
    let response = await httpClient.get(`/search`, {
        params: {
            query: query,
        },
    })
    return response.data
}

export async function sendFriendRequest(userId) {
    let response = await httpClient.post(`/friendRequests/outgoing`, {
        userId: userId,
    })
    return response.data
}

export async function cancelFriendRequest(userId) {
    let response = await httpClient.delete(`/friendRequests/outgoing/${userId}`)
    return response.data
}

export async function acceptFriendRequest(userId) {
    let response = await httpClient.post(`/friendRequests/incoming/${userId}/accept`)
    return response.data
}

export async function rejectFriendRequest(userId) {
    let response = await httpClient.post(`/friendRequests/incoming/${userId}/reject`)
    return response.data
}

export async function getIncomingFriendRequests(limit) {
    let response = await httpClient.get("/friendRequests/incoming", {
        params: {
            limit: limit,
        },
    })
    return response.data
}

export async function unfriend(userId) {
    let response = await httpClient.delete(`/friends/${userId}`)
    return response.data
}

export async function getUserDb() {
    let response = await httpClient.get("/userDb")
    return response.data
}

export async function logout() {
    await httpClient.delete(`/auth`)
}

export async function getPlayerState() {
    const response = await httpClient.get(`/player/state`)
    return response.data
}

export async function pause() {
    await httpClient.post("/player/pause")
}

export async function resume() {
    await httpClient.post("/player/resume")
}

export async function skipToNext() {
    await httpClient.post("/player/skipToNext")
}

export async function skipToPrevious() {
    await httpClient.post("/player/skipToPrevious")
}

export async function play({songId}) {
    await httpClient.post(`/player/play`, {
        songId
    })
}