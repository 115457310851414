import {Bubbles} from "./Bubbles"
import {useAlbums} from "../stores/AlbumStore"

export function AlbumBubbles({groups}) {
    const albumIds = groups.map(group => group.entityId);
    const results = useAlbums(albumIds);

    const albumsById = results
        .filter(result => !result.isPending)
        .reduce((acc, {data: album}) => {
            acc[album.id] = album
            return acc
        }, {})

    const data = groups
        .map(group => {
            const album = albumsById[group.entityId];
            return {
                id: group.entityId,
                name: album?.name,
                value: group.count,
                imageUrl: album?.images[0]?.url,
                path: `/album/${group.entityId}`
            };
        })

    return <Bubbles data={data}/>
}