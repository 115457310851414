import {useAutoQuery} from "../../utils"
import {getArtistAlbums} from "../../api/ApiClient"
import {Card, CardContent, CardHeader, CardTitle} from "../../components/ui/card"
import {Link} from "react-router-dom"
import React from "react"

export function DiscographyTab({artistId}) {
    const {data: albums, isPending} = useAutoQuery(getArtistAlbums, artistId)

    if (isPending || albums.length === 0) {
        return
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>
                    Discography
                </CardTitle>
            </CardHeader>
            <CardContent>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                    {albums.map(album => (
                        <Link to={`/album/${album.id}`} key={album.id} className="flex flex-col items-center text-center">
                            <div className="relative mb-2">
                                <img
                                    src={album.images[0]?.url}
                                    alt={album.name}
                                    className="w-full aspect-square object-cover rounded-md shadow-lg"
                                />
                                <div className="absolute bottom-2 right-2 bg-gray-800 text-primary-foreground px-2 py-1 rounded-full text-xs font-semibold">
                                    {album.releaseDate.year}
                                </div>
                            </div>
                            <h4 className="font-semibold text-sm line-clamp-1">{album.name}</h4>
                            <p className="text-xs line-clamp-1">{album.artistCredit.segments.map(segment => segment.text).join("")}</p>
                        </Link>
                    ))}
                </div>
            </CardContent>
        </Card>
    )
}