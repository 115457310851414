import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "./ui/card"
import {BarChart2Icon, MusicIcon, UploadIcon} from "lucide-react"
import * as PropTypes from "prop-types"
import {Button} from "./ui/button"
import {Link} from "react-router-dom"

function Progress(props) {
    return null
}

Progress.propTypes = {
    className: PropTypes.string,
    value: PropTypes.number,
}

export function UploadSpotifyListeningHistoryWidget() {
    return (
        <Card className="w-full max-w-md">
            <CardHeader className="bg-gradient-to-r from-purple-500 to-indigo-700 text-white -m-4 rounded-t-md p-4">
                <CardTitle className="flex items-center gap-2">
                    <UploadIcon className="w-6 h-6"/>
                    Upload Your Spotify Data
                </CardTitle>
                <CardDescription className="text-purple-100">
                    Get personalized insights from your listening history
                </CardDescription>
            </CardHeader>
            <CardContent className="pt-6">
                <div className="space-y-4">
                    <div className="flex items-center gap-4">
                        <MusicIcon className="w-8 h-8 text-purple-500"/>
                        <div className="flex-1">
                            <p className="text-sm font-medium">Analyze Your Taste</p>
                            <p className="text-xs text-muted-foreground">Discover your top artists and genres</p>
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <BarChart2Icon className="w-8 h-8 text-indigo-700"/>
                        <div className="flex-1">
                            <p className="text-sm font-medium">Track Your Trends</p>
                            <p className="text-xs text-muted-foreground">See how your music preferences evolve</p>
                        </div>
                    </div>
                </div>
            </CardContent>
            <CardFooter className="w-full">
                <Link to="/spotify-data-upload" className="w-full text-foreground">
                    <Button className="w-full flex flex-row gap-2 bg-gradient-to-r from-purple-500 to-indigo-700">
                        <UploadIcon className="w-6 h-6"/>
                        Start Upload
                    </Button>
                </Link>
            </CardFooter>
        </Card>
    )
}