import {Link, useLocation, useSearchParams} from "react-router-dom"
import {useAutoQuery, useIsMobile} from "../utils"
import React, {useState} from "react"
import {useAlbum} from "../stores/AlbumStore"
import {searchWithReordering} from "../db/DbService"
import {SongCard} from "../components/SongCard"
import {ArtistCard} from "../components/ArtistCard"
import {Input} from "../components/ui/input"
import {LoadingPage} from "./LoadingPage"

export function SearchPage() {
    const location = useLocation()
    const query = new URLSearchParams(location.search).get("q")

    const {data: results, isPending} = useAutoQuery(searchWithReordering, query)

    return (
        <div className="flex flex-col flex-grow gap-2 w-full">
            <Header query={query}/>
            <div className="flex flex-col flex-grow gap-4 w-full">
                {
                    isPending ? <LoadingPage/> :
                    <>
                        <Artists artists={results.artists}/>
                        <Albums albums={results.albums}/>
                        <Songs results={results.songs}/>
                    </>
                }
            </div>
        </div>
    )

}

function Header({query}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchValue, setSearchValue] = useState(query || '')
    const isMobile = useIsMobile()

    const handleSubmit = e => {
        e.preventDefault()
        const formData = new FormData(e.target)
        searchParams.set('q', formData.get('search'))
        setSearchParams(searchParams)
    }

    if(isMobile) {
        return (
            <form onSubmit={handleSubmit}>
                <Input
                    name="search"
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                    placeholder="Search"
                    className="text-foreground"
                />
            </form>
        )
    } else {
        return (
            <div className="flex items-center">
                <div>
                    <h1 className="text-3xl font-bold text-foreground">Search Results</h1>
                    <p className="text-lg text-muted-foreground">for "{query}"</p>
                </div>
            </div>
        )
    }
}

function Artists({artists}) {
    if (artists.length === 0) {
        return null
    }

    return (
        <section className="flex flex-col gap-2">
            <h2 className="text-2xl font-bold text-foreground">Artists</h2>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                {artists
                    .slice(0, 8)
                    .map(result => <ArtistCard artistId={result.id} key={result.id}/>)
                }
            </div>
        </section>
    )
}

function Albums({albums}) {
    if (albums.length === 0) {
        return null
    }

    return (
        <section className="flex flex-col gap-2">
            <h2 className="text-2xl font-bold text-foreground">Albums</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
                {albums
                    .slice(0, 10)
                    .map(result => <Album result={result} key={result.id}/>)
                }
            </div>
        </section>
    )
}

function Album({result}) {
    const {data: album, isPending} = useAlbum(result.id)

    if(isPending) {
        return
    }

    return (
        <Link to={`/album/${album.id}`} key={album.id} className="flex flex-col items-center text-center gap-2">
            <div className="relative">
                <img
                    src={album.images[0]?.url}
                    alt={album.name}
                    className="w-full aspect-square object-cover rounded-md shadow-lg"
                />
                <div className="absolute bottom-2 right-2 bg-primary text-primary-foreground px-2 py-1 rounded-full text-xs font-semibold">
                    {album.releaseDate.year}
                </div>
            </div>
            <div className="flex flex-col">
                <h4 className="font-semibold text-sm line-clamp-1">{album.name}</h4>
                <p className="text-xs line-clamp-1">{album.artistCredit.segments.map(segment => segment.text).join("")}</p>
            </div>
        </Link>
    )
}

function Songs({results}) {
    if (results.length === 0) {
        return null
    }
    
    return (
        <section className="flex flex-col gap-2">
            <h2 className="text-2xl font-bold text-foreground">Songs</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
                {results
                    .slice(0, 10)
                    .map(result => <SongCard songId={result.id} key={result.id}/>)
                }
            </div>
        </section>
    )
}