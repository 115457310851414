import React from "react"
import {formatCount, formatListenDuration, useAutoQuery} from "../../utils"
import {Card, CardContent, CardHeader, CardTitle} from "../ui/card"
import {getTopDecades} from "../../db/DbService"
import {useSong} from "../../stores/SongStore"
import {Hourglass} from "lucide-react"

export function ByTheDecadeCard({start, end}) {
    const {data: groups, isPending} = useAutoQuery(getTopDecades, {
        start,
        end,
        limit: 4,
        limitPerDecade: 8
    })

    if (isPending) {
        return
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle className="flex items-center gap-2">
                    <Hourglass className="h-6 w-6"/>
                    By the Decade
                </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-2">
                {groups.map(group => <Decade group={group} key={group.entityId}/>)}
            </CardContent>
        </Card>
    );
}

function Decade({group}) {
    return (
        <div className="flex flex-col justify-center gap-1 relative rounded-md overflow-hidden">
            <DecadeBackground group={group}/>
            <div className="w-full h-full absolute p-2 inset-0 flex flex-row justify-between text-white">
                <h4 className="font-bold text-2xl line-clamp-1">{group.entityId}s</h4>
                <div className="flex flex-col items-end">
                    <span>{formatCount(group.count)} listens</span>
                    <span>{formatListenDuration(group.durationMs)}</span>
                </div>
            </div>
        </div>
    )
}

function DecadeBackground({group}) {
    return (
        <div className="relative w-full">
            <div className="grid grid-cols-4 w-full z-0">
                {group.songs.map(song => <SongImage songId={song.songId} key={song.songId}/>)}
            </div>
            <div className="absolute inset-0 bg-gradient-to-b from-black/80 to-transparent pointer-events-none" />
        </div>
    )
}

function SongImage({songId}) {
    const {data: song, isPending} = useSong(songId)

    if (isPending) {
        return
    }

    return (
        <img
            src={song.images[0].url}
            alt={song.name}
            className="aspect-square object-cover"
        />
    )
}