import {useStoreWithEqualityFn} from "zustand/traditional"
import {currentUserStore} from "../stores/CurrentUserStore"
import {UploadSpotifyListeningHistoryWidget} from "../components/UploadSpotifyListeningHistoryWidget"
import {epoch, roundedNow, useAutoQuery, useMeasureRem} from "../utils"
import {getSpotifyBackfillImports} from "../api/ApiClient"
import {SummaryBar} from "../components/home/SummaryBar"
import {ThisWeekSoFarCard} from "../components/home/ThisWeekSoFarCard"
import {OneHitWondersCard} from "../components/home/OneHitWondersCard"
import Masonry from "react-masonry-css"
import {JourneyCard} from "../components/home/JourneyCard"
import {BlastFromThePastCard} from "../components/home/BlastFromThePastCard"
import {ByTheDecadeCard} from "../components/home/ByTheDecadeCard"
import {NewDiscoveriesCard} from "../components/home/NewDiscoveriesCard";
import {RecentPlaysCard} from "../components/home/RecentPlaysCard";
import {Card, CardContent} from "../components/ui/card";
import {Music2} from "lucide-react";

export function HomePage() {
    const {data: imports} = useAutoQuery(getSpotifyBackfillImports)
    const showImportWidget = imports?.filter(i => i.state === "PROCESSING" || i.state === "PROCESSED")?.length === 0

    return (
        <div className="w-full flex flex-col gap-4">
            <Header/>
            <SummaryBar/>
            <Grid>
                {showImportWidget ? <UploadSpotifyListeningHistoryWidget/> : null}
                <ThisWeekSoFarCard/>
                <JourneyCard/>
                <OneHitWondersCard/>
                <BlastFromThePastCard/>
                <NewDiscoveriesCard/>
                <ByTheDecadeCard start={epoch()} end={roundedNow()} limit={10}/>
                <RecentPlaysCard/>
            </Grid>
        </div>
    )
}

function Header() {
    const { currentUser } = useStoreWithEqualityFn(currentUserStore);

    return (
        <Card className="w-full bg-gradient-to-r from-violet-600 to-orange-500 border-none shadow-xl">
            <CardContent className="flex flex-col sm:flex-row items-center gap-6 p-4">
                <div className="p-4 bg-white/10 rounded-full backdrop-blur-sm shrink-0">
                    <Music2 className="w-8 h-8 text-white" />
                </div>
                <div className="flex flex-col gap-2 text-center sm:text-left">
                    <h1 className="text-3xl sm:text-4xl font-bold text-white">
                        Hey there, {currentUser?.displayName}
                    </h1>
                    <p className="text-lg sm:text-xl text-white/90">
                        Let's dive into your soundtrack
                    </p>
                </div>
            </CardContent>
        </Card>
    );
}

function Grid({children}) {
    const [ref, {width}] = useMeasureRem()
    const columns = Math.floor(width / 20)

    return (
        <div className="w-full" ref={ref}>
            <Masonry breakpointCols={{default: columns}} ref={ref} className="flex flex-row gap-8 w-full" columnClassName="flex flex-col gap-8">
                {children.filter(child => child != null)}
            </Masonry>
        </div>
    )
}