import * as d3 from "d3"
import {useMemo} from "react"
import {useNavigate} from "react-router-dom"
import {useMeasure} from "react-use"

export function Bubbles({ data }) {
    const navigate = useNavigate();
    const [ref, { width, height }] = useMeasure();

    const nodes = useMemo(() => {
        if (!width || !height) return [];

        const SCALE_FACTOR = 1.2; // Overscale factor
        const size = Math.sqrt(width * height) * SCALE_FACTOR;
        const hierarchy = d3
            .hierarchy({ children: data })
            .sum((d) => d.value)
            .sort((a, b) => b.value - a.value);

        const packGenerator = d3.pack()
            .size([size, size]);

        const root = packGenerator(hierarchy);

        const initialNodes = root.descendants().slice(1).map(node => ({
            ...node,
            x: node.x * (width / size) * SCALE_FACTOR,
            y: node.y * (height / size) * SCALE_FACTOR,
            r: node.r * Math.sqrt((width * height) / (size * size)) * SCALE_FACTOR
        }));

        const boundaryForce = (alpha) => {
            const padding = 2;
            const strength = 50;

            for (let node of initialNodes) {
                if (node.x - node.r < padding) {
                    node.vx += strength * alpha;
                }
                if (node.x + node.r > width - padding) {
                    node.vx -= strength * alpha;
                }
                if (node.y - node.r < padding) {
                    node.vy += strength * alpha;
                }
                if (node.y + node.r > height - padding) {
                    node.vy -= strength * alpha;
                }
            }
        };

        const simulation = d3.forceSimulation(initialNodes)
            .force("center", d3.forceCenter(width / 2, height / 2))
            .force("charge", d3.forceManyBody().strength(5))
            .force("collide", d3.forceCollide().radius(d => d.r + 1).strength(1))
            .force("boundary", boundaryForce)
            .stop();

        for (let i = 0; i < 300; ++i) simulation.tick();

        simulation.stop();

        return [...initialNodes];
    }, [data, width, height]);

    if (!width || !height) {
        return <div className="w-full h-full" ref={ref}></div>;
    }

    const handleNodeClick = (node, event) => {
        event.preventDefault();
        navigate(node.data.path);
    };

    if (nodes.length === 0) return null;

    return (
        <div ref={ref} className="w-full h-full bg-card">
            <svg
                width={width}
                height={height}
                style={{ display: "inline-block", overflow: "hidden" }}
            >
                <defs>
                    {nodes.map(node => (
                        <clipPath key={`clip-${node.data.id}`} id={`clip-${node.data.id}`}>
                            <circle cx={node.x} cy={node.y} r={node.r} />
                        </clipPath>
                    ))}
                </defs>

                <g>
                    {nodes.map(node => (
                        <g
                            key={node.data.id}
                            onClick={(e) => handleNodeClick(node, e)}
                            style={{ cursor: 'pointer' }}
                        >
                            <circle
                                cx={node.x}
                                cy={node.y}
                                r={node.r}
                                fill="#B794F4"
                                fillOpacity={0.2}
                            >
                                <title>{node.data.name}</title>
                            </circle>
                            <image
                                href={node.data.imageUrl}
                                x={node.x - node.r}
                                y={node.y - node.r}
                                width={node.r * 2}
                                height={node.r * 2}
                                clipPath={`url(#clip-${node.data.id})`}
                                preserveAspectRatio="xMidYMid slice"
                                style={{ pointerEvents: 'none' }}
                            />
                        </g>
                    ))}
                </g>
            </svg>
        </div>
    );
}