import { Card, CardContent, CardHeader } from "../components/ui/card"
import { Button } from "../components/ui/button"

export function SettingsPage() {
    return (
        <div className="flex flex-col gap-2">
            <Connections/>
        </div>
    )
}

function Connections() {
    return (
        <div className="flex flex-col gap-1">
            <h2 className="text-4xl font-bold">Connections</h2>
            <div className="grid grid-cols-3 gap-2">
                <MusicbrainzConnection/>
            </div>
        </div>
    )
}

function MusicbrainzConnection() {
    return (
        <Card>
            <CardHeader className="flex flex-row items-center gap-1">
                <img src="/musicbrainz.png" className="w-[2rem] h-[2rem]" alt="Musicbrainz"/>
                <span className="text-xl">Musicbrainz</span>
            </CardHeader>
            <CardContent>
                <Button>
                    Connect
                </Button>
            </CardContent>
        </Card>
    )
}