import {cn} from "../utils";

export function InterpunctList({className, children}) {
    const nonNullChildren = children.filter(item => item)

    if(nonNullChildren.length === 0) {
        return null
    }

    return (
        <div className={cn("flex flex-row items-center gap-1 text-gray-200", className)}>
            {nonNullChildren.map((item, index) => (
                <>
                    {index > 0 && (
                        <div className="h-1 aspect-square bg-gray-200 rounded-full" key={"interpunct-" + index} />
                    )}
                    {item}
                </>
            ))}
        </div>
    )
}