import React from "react"
import { Button } from "../components/ui/button"
import { BarChart3Icon, ClockIcon, UsersIcon } from "lucide-react"
import { Link } from "react-router-dom"

export function LandingPage() {
    return (
        <div className="w-full h-full flex flex-col flex-grow gap-6 items-center justify-center text-center">
            <div className="flex flex-col gap-6 items-center max-w-2xl">
                <h1 className="text-5xl sm:text-5xl md:text-6xl lg:text-8xl font-bold tracking-tighter bg-gradient-to-r from-purple-500 to-orange-500 text-transparent bg-clip-text">
                    Discover Your Music Journey
                </h1>
                <p className="text-lg sm:text-xl text-foreground">
                    Unlock deep insights into your listening habits, explore your musical tastes, and connect with your
                    favorite artists like never before.
                </p>
                <div
                    className="flex flex-row gap-4 items-center justify-center">
                    <Link to="/login">
                        <Button className="bg-accent text-accent-foreground">Get Started</Button>
                    </Link>
                    <Button variant="outline" className="border-accent text-accent">Learn
                        More</Button>
                </div>
            </div>
            <div className="grid gap-8 grid-cols-1 sm:grid-cols-3 max-w-4xl">
                <Point
                    icon={BarChart3Icon}
                    title="In-depth Analytics"
                    text="Dive deep into your listening patterns with detailed charts and statistics."
                />
                <Point
                    icon={ClockIcon}
                    title="Listening History"
                    text="Track your music journey over time and rediscover forgotten favorites."
                />
                <Point
                    icon={UsersIcon}
                    title="Music Community"
                    text="Share playlists, follow friends, and discover music."
                />
            </div>
        </div>
    );
}

function Point({icon: Icon, title, text}) {
    return (
        <div className="flex flex-col gap-2 items-center text-center">
            <Icon className="h-12 w-12 text-accent"/>
            <h3 className="text-xl font-bold text-foreground">{title}</h3>
            <p className="text-sm text-muted-foreground">{text}</p>
        </div>
    )
}