import {Card, CardContent, CardHeader, CardTitle} from "../ui/card";
import {getArtistCreditAsString, useAutoQuery} from "../../utils";
import {Headphones} from "lucide-react";
import {getRecentListens} from "../../db/DbClient";
import {useSong} from "../../stores/SongStore";
import {DateTime} from "luxon";
import {Link} from "react-router-dom";

export function RecentPlaysCard() {
    const {data: listens} = useAutoQuery(getRecentListens, {limit: 5})

    return (
        <Card>
            <CardHeader className="flex flex-col gap-1">
                <CardTitle className="flex items-center gap-2">
                    <Headphones className="h-6 w-6"/>
                    Recent Plays
                </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-1">
                {listens?.map(listen => <Listen listen={listen} key={[listen.songId, listen.timestamp]}/>)}
            </CardContent>
        </Card>
    )
}

function Listen({listen}) {
    const {data: song} = useSong(listen.songId)
    const timestamp = DateTime.fromMillis(listen.timestamp)

    if(!song) {
        return
    }

    return (
        <Link to={`/song/${listen.songId}`} className="flex flex-row gap-2 items-center justify-between h-12">
            <div className="flex flex-row items-center gap-2 h-full">
                <img src={song?.images[0]?.url} alt={song?.name} className="h-full aspect-square rounded-md"/>
                <div className="flex flex-col">
                    <span className="text-sm font-semibold line-clamp-1">{song?.name}</span>
                    <span className="text-xs line-clamp-1">{getArtistCreditAsString(song?.artistCredit)}</span>
                </div>
            </div>
            <span className="flex-shrink-0 text-xs">{formatTimeSince(timestamp)}</span>
        </Link>

    )
}

function formatTimeSince(timestamp) {
    const units = ["days", "hours", "minutes", "seconds"]
    const diff = DateTime.now().diff(timestamp, units)

    for(const unit of units) {
        if(diff.values[unit]) {
            return `${diff.values[unit]} ${unit} ago`
        }
    }

    return "now"
}