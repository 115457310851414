import React from "react"
import {DesktopHeader} from "./DesktopHeader"
import {DesktopPlayerBar} from "./PlayerBar"
import {useMeasure} from "react-use";

export function DesktopLayout({children}) {
    const [headerRef, {height: headerHeight}] = useMeasure()
    const [playerBarRef, {height: playerBarHeight}] = useMeasure()

    return (
        <>
            <div className="fixed top-0 left-0 right-0 w-full z-10" ref={headerRef}>
                <DesktopHeader/>
            </div>
            <div className="w-full bg-background">
                <main className="flex flex-col bg-background w-full min-h-[100dvh] max-w-6xl p-2 mx-auto">
                    <div style={{height: headerHeight}}/>
                    {children}
                    <div style={{height: playerBarHeight}}/>
                </main>
            </div>
            <div className="fixed bottom-0 left-0 right-0 w-full" ref={playerBarRef}>
                <DesktopPlayerBar/>
            </div>
        </>
    );
}
