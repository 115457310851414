import {getDatabase} from "../stores/DatabaseStore"
import camelcaseKeys from "camelcase-keys"
import {DateTime} from "luxon"

export function getTopYears() {
    const sql = `
        WITH listen AS (
            SELECT
                EXTRACT(YEAR FROM timestamp) as entity_id,
                timestamp,
                null as partition,
                null as rank,
                duration_ms,
                weight
            FROM song_listen
        )
        SELECT *
        FROM top_entities()
    `
    return query(sql)
}

export function getTopArtistsForOther({start, end, limit}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM artist_listen
            WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
        )
        SELECT *
        FROM top_entities()
        LIMIT ?
    `
    return query(
        sql,
        start,
        end,
        limit,
    )
}

export function getTopArtistsForGenre({genreId, start, end, limit}) {
    const sql = `
        WITH listen AS (
            SELECT
                l.entity_id,
                l.timestamp,
                l.duration_ms,
                sg.weight
            FROM artist_listen l
            JOIN song_genre sg ON l.song_id = sg.song_id
            WHERE
                genre_id = ? AND
                timestamp BETWEEN ?::timestamp AND ?::timestamp
        )
        SELECT *
        FROM top_entities()
        LIMIT ?
    `
    return query(
        sql,
        genreId,
        start,
        end,
        limit,
    )
}

export async function getTopNewlyDiscoveredArtists({discoveredAfter, limit}) {
    const sql = `
        WITH listen AS (
            SELECT
                *,
                MIN(timestamp) OVER (PARTITION BY artist_id) as artist_discovery
            FROM artist_listen
            QUALIFY artist_discovery >= ?::timestamp
        )
        SELECT *
        FROM top_entities()
        LIMIT ?
    `
    return query(
        sql,
        discoveredAfter,
        limit,
    )
}

export async function getTopNewlyDiscoveredSongs({limit}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM song_listen
        )
        SELECT *
        FROM top_entities()
        WHERE count >= 5
        ORDER BY min_timestamp DESC
        LIMIT ?
    `
    return query(
        sql,
        limit,
    )
}

export function getTopAlbums({artistId, start, end, limit}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM album_listen(artist_id := ?)
            WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp    
        )
        SELECT *
        FROM top_entities()
        LIMIT ?
    `
    return query(
        sql,
        artistId,
        start,
        end,
        limit,
    )
}

export function getTopSongsForOther({artistId, albumId, start, end, limit}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM song_listen(artist_id := ?, album_id := ?)
            WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp    
        )
        SELECT *
        FROM top_entities()
        LIMIT ?
    `
    return query(
        sql,
        artistId,
        albumId,
        start,
        end,
        limit,
    )
}

export function getOneHitWonders({limit}) {
    const sql = `
        WITH artist_summary AS (
            WITH listen AS (
                SELECT *
                FROM artist_listen
            )
            SELECT *
            FROM top_entities()
        ), song_artist_count AS (
            SELECT 
                s.id as song_id, 
                COUNT(*) as artist_count
            FROM song s
            JOIN artist_credit_artist aca ON s.artist_credit_id = aca.artist_credit_id
            GROUP BY s.id
        )
        SELECT
            l.song_id as entity_id,
            COUNT(*) as count,
            SUM(l.duration_ms) as duration_ms,
            COUNT(*) / ANY_VALUE(asum.count) as prop
        FROM artist_listen l
        JOIN song_artist_count sac ON l.song_id = sac.song_id
        JOIN artist_summary asum ON l.artist_id = asum.entity_id
        WHERE sac.artist_count = 1
        GROUP BY l.song_id
        HAVING prop > 0.95
        ORDER BY count DESC
        LIMIT ?
    `
    return query(sql, limit)
}

export function getTopSongsForGenre({genreId, start, end, limit}) {
    const sql = `
        WITH listen AS (
            SELECT
                sl.song_id as entity_id,
                sl.timestamp,
                sl.duration_ms,
                sg.weight
            FROM song_listen sl
            JOIN song_genre sg ON sl.song_id = sg.song_id   
            WHERE
                genre_id = ? AND
                timestamp BETWEEN ?::timestamp AND ?::timestamp
        )
        SELECT *
        FROM top_entities()
        LIMIT ?
    `
    return query(
        sql,
        genreId,
        start,
        end,
        limit,
    )
}

export function getTopGenres({start, end, limit}) {
    const sql = `
        SELECT
            genre_id,
            genre_id as entity_id,
            COUNT(*) as count,
            SUM(duration_ms) as duration_ms
        FROM song_listen l
        JOIN song_genre sg ON l.song_id = sg.song_id
        WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
        GROUP BY genre_id
        ORDER BY count DESC
        LIMIT ?
    `
    return query(
        sql,
        start,
        end,
        limit,
    )
}

export async function getRecentListens({limit}) {
    const sql = `
        SELECT *
        FROM song_listen
        ORDER BY timestamp DESC
        LIMIT ?
    `
    return query(sql, limit)
}

export async function getSongCount({start, end, artistId, albumId}) {
    const sql = `
        SELECT COUNT(DISTINCT song_id)
        FROM song_listen(artist_id := ?, album_id := ?)
        WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
    `
    return querySingleColumn(sql, artistId, albumId, start, end)
}

export async function getDiscoveredArtistCount({start, end}) {
    const sql = `
        WITH artist_discovery AS (
            SELECT
                artist_id, MIN (timestamp) as timestamp
            FROM artist_listen
            GROUP BY artist_id
        )
        SELECT COUNT (*)
        FROM artist_discovery
        WHERE timestamp BETWEEN ?:: timestamp AND ?:: timestamp
    `
    return querySingleColumn(sql, start, end)
}

export async function getSummary({start, end, artistId, albumId}) {
    const sql = `
        SELECT
            COUNT(*) as count,
            COALESCE(SUM(duration_ms), 0) as duration_ms,
            MIN(timestamp) as first_listen,
            MAX(timestamp) as last_listen
        FROM song_listen(artist_id := ?, album_id := ?)
        WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
    `
    return querySingle(
        sql,
        artistId,
        albumId,
        start,
        end,
    )
}

export function getSongSummary({songId, start, end}) {
    const sql = `
        WITH relevant_listen AS (
            SELECT
                *
            FROM song_listen l
            WHERE
                l.timestamp BETWEEN ?::timestamp AND ?::timestamp AND
                l.song_id = ?
        ), listen_count AS (
            SELECT COALESCE(COUNT(*), 0)
            FROM relevant_listen
        ), duration_ms AS (
            SELECT COALESCE(SUM(duration_ms), 0)
            FROM relevant_listen
        ), rank AS (
            WITH song_listen_group AS (
                SELECT
                    song_id,
                    COUNT(*) as count
                FROM song_listen
                WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
                GROUP BY song_id
            ), ranked_song AS (
                SELECT
                    song_id,
                    RANK() OVER (ORDER BY count DESC) as rank
                FROM song_listen_group
            )
            SELECT rank
            FROM ranked_song
            WHERE song_id = ?
        ), first_listen AS (
            SELECT MIN(timestamp)
            FROM relevant_listen
        ), last_listen AS (
            SELECT MAX(timestamp)
            FROM relevant_listen
        )
        SELECT
            (SELECT * FROM listen_count) as listen_count,
            (SELECT * FROM duration_ms) as duration_ms,
            (SELECT * FROM rank) as rank,
            (SELECT * FROM first_listen) as first_listen,
            (SELECT * FROM last_listen) as last_listen
    `
    return querySingle(
        sql,
        start,
        end,
        songId,
        start,
        end,
        songId,
    )
}

export function getArtistSummary({artistId, start, end}) {
    const sql = `
        WITH relevant_listen AS (
            SELECT
                *
            FROM artist_listen
            WHERE
                timestamp BETWEEN ?::timestamp AND ?::timestamp AND
                artist_id = ?
        ), listen_count AS (
            SELECT COALESCE(COUNT(*), 0)
            FROM relevant_listen
        ), duration_ms AS (
            SELECT COALESCE(SUM(duration_ms), 0)
            FROM relevant_listen
        )
        SELECT
            (SELECT * FROM listen_count) as listen_count,
            (SELECT * FROM duration_ms) as duration_ms
    `
    return querySingle(
        sql,
        start,
        end,
        artistId,
    )
}

export async function getArtistRank({artistId, start, end}) {
    const sql = `
        WITH artist_listen_group AS (
            SELECT
                artist_id,
                COUNT(*) as count,
                SUM(duration_ms) as duration_ms
            FROM artist_listen l
            WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
            GROUP BY artist_id
        ), ranked_artist AS (
            SELECT
                artist_id,
                ROW_NUMBER() OVER (ORDER BY count DESC, duration_ms DESC, artist_id ASC) as rank
            FROM artist_listen_group
        )
        SELECT rank
        FROM ranked_artist
        WHERE artist_id = ?
    `
    return querySingleColumn(sql, start, end, artistId)
}

export function getAlbumSummary({albumId, start, end}) {
    const sql = `
        WITH relevant_listen AS (
            SELECT
                *
            FROM album_listen l
            WHERE
                l.timestamp BETWEEN ?::timestamp AND ?::timestamp AND
                l.album_id = ?
        ), listen_count AS (
            SELECT COALESCE(COUNT(*), 0)
            FROM relevant_listen
        ), duration_ms AS (
            SELECT COALESCE(SUM(duration_ms), 0)
            FROM relevant_listen
        ), rank AS (
            WITH album_listen_group AS (
                SELECT
                    album_id,
                    COUNT(*) as count
                FROM album_listen
                WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
                GROUP BY album_id
            ), ranked_album AS (
                SELECT
                    album_id,
                    RANK() OVER (ORDER BY count DESC) as rank
                FROM album_listen_group
            )
            SELECT rank
            FROM ranked_album
            WHERE album_id = ?
        ), first_listen AS (
            SELECT MIN(timestamp)
            FROM relevant_listen
        ), last_listen AS (
            SELECT MAX(timestamp)
            FROM relevant_listen
        )
        SELECT
            (SELECT * FROM listen_count) as listen_count,
            (SELECT * FROM duration_ms) as duration_ms,
            (SELECT * FROM rank) as rank,
            (SELECT * FROM first_listen) as first_listen,
            (SELECT * FROM last_listen) as last_listen
    `
    return querySingle(
        sql,
        start,
        end,
        albumId,
        start,
        end,
        albumId,
    )
}

export async function getTopNArtistsByYears({n}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM artist_listen
        )
        SELECT *
        FROM top_n_entities_by_year(?)
    `
    return query(sql, n)
}

export async function getTopNSongByYears({n}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM song_listen
        )
        SELECT *
        FROM top_n_entities_by_year(?)
    `
    return query(sql, n)
}

export async function getTopNAlbumsByYears({n}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM album_listen
        )
        SELECT *
        FROM top_n_entities_by_year(?)
    `
    return query(sql, n)
}

export async function getTopNGenresByYears({n}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM genre_listen
        )
        SELECT *
        FROM top_n_entities_by_year(?)
    `
    return query(sql, n)
}

export async function getDiscoveredArtistCountByYear() {
    const sql = `
        WITH artist_discovery AS (
            SELECT
                artist_id,
                MIN(timestamp) as timestamp
            FROM artist_listen
            GROUP BY artist_id
        )
        SELECT
            EXTRACT(YEAR FROM timestamp) as year,
            COUNT(*) as count
        FROM artist_discovery
        GROUP BY year
    `
    return query(sql)
}

export async function getTopNHoursByYear({n}) {
    const sql = `
        WITH listen AS (
            SELECT
                EXTRACT(HOUR FROM timestamp) AS entity_id,
                duration_ms,
                timestamp
            FROM song_listen   
        )
        SELECT *
        FROM top_n_entities_by_year(?)
    `
    return query(sql, n)
}


export async function getArtistsGraphListenGroups({distinctCount, start, end, datePart}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM artist_listen
            WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
        )
        SELECT *
        FROM graph(?, ?)
    `
    return query(
        sql,
        start,
        end,
        distinctCount,
        datePart,
    )
}

export async function getAlbumGraphListenGroups({artistId, start, end, distinctCount, datePart}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM album_listen(artist_id := ?)
            WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
        )
        SELECT *
        FROM graph(?, ?)
    `
    return query(
        sql,
        artistId,
        start,
        end,
        distinctCount,
        datePart,
    )
}

export async function getSongGraphListenGroups({artistId, albumId, start, end, distinctCount, datePart}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM song_listen(artist_id := ?, album_id := ?)
            WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
        )
        SELECT *
        FROM graph(?, ?)
    `
    return query(
        sql,
        artistId,
        albumId,
        start,
        end,
        distinctCount,
        datePart,
    )
}

export async function calculateArtistRelevances() {
    await execute(`
        CREATE TABLE artist_relevance AS
        SELECT
            artist_id,
            1.0 - EXP(-CAST(COUNT(*) AS FLOAT) / 100.0) AS relevance
        FROM artist_listen
        GROUP BY artist_id;
    `)

    await execute(`
        CREATE UNIQUE INDEX artist_relevance_artist_id_idx ON artist_relevance (artist_id);
    `)
}

export async function getArtistRelevance(artistId) {
    const sql = `
        SELECT relevance
        FROM artist_relevance
        WHERE artist_id = ?
    `
    return querySingleColumn(sql, artistId)
}

export async function calculateAlbumRelevances() {
    await execute(`
        CREATE TABLE album_relevance AS
        SELECT
            album_id,
            1.0 - EXP(-CAST(COUNT(*) AS FLOAT) / 25.0) AS relevance
        FROM album_listen
        GROUP BY album_id;
    `)

    await execute(`
        CREATE UNIQUE INDEX album_relevance_album_id_idx ON album_relevance (album_id);
    `)
}

export async function getAlbumRelevance(albumId) {
    const sql = `
        SELECT relevance
        FROM album_relevance
        WHERE album_id = ?
    `
    return querySingleColumn(sql, albumId)
}

export async function calculateSongRelevances() {
    await execute(`
        CREATE TABLE song_relevance AS
        SELECT
            song_id,
            1.0 - EXP(-CAST(COUNT(*) AS FLOAT) / 10.0) AS relevance
        FROM song_listen
        GROUP BY song_id;
    `)

    await execute(`
        CREATE UNIQUE INDEX song_relevance_song_id_idx ON song_relevance (song_id);
    `)
}

export async function getSongRelevance(songId) {
    const sql = `
        SELECT relevance
        FROM song_relevance
        WHERE song_id = ?
    `
    return querySingleColumn(sql, songId)
}

export async function calculateArtistStreaks() {
    const sql = `
        CREATE TABLE artist_streak AS
        WITH streak_data AS (
            SELECT
                artist_id,
                timestamp,
                LAG(timestamp) OVER (PARTITION BY artist_id ORDER BY timestamp) AS previous_timestamp,
                timestamp - previous_timestamp >= INTERVAL '1 day' as new_streak
            FROM artist_listen
        ), calc AS (
            SELECT
                artist_id,
                timestamp,
                SUM(CASE WHEN new_streak THEN 1 ELSE 0 END) OVER (
                    PARTITION BY artist_id
                    ORDER BY timestamp
                    ROWS BETWEEN UNBOUNDED PRECEDING AND CURRENT ROW
                ) AS streak_id
            FROM streak_data
        )
        SELECT
            artist_id,
            MIN(timestamp) AS start,
            MAX(timestamp) AS end,
            DATEDIFF('days', start, "end") AS length,
            NOW() - "end" <= INTERVAL '24 hours' as active,
            NOW() - "end" <= INTERVAL '12 hours' as needs_reup
        FROM calc
        GROUP BY artist_id, streak_id
        HAVING length >= 2
    `

    await execute(sql)
}

export async function getLongestArtistStreak({artistId}) {
    const sql = `
        SELECT *
        FROM artist_streak
        WHERE artist_id = ?
        ORDER BY length DESC, "end" DESC
        LIMIT 1
    `
    return querySingle(sql, artistId)
}

export async function getLongestActiveArtistStreaks({limit}) {
    const sql = `
        SELECT *
        FROM artist_streak
        WHERE active
        ORDER BY length DESC, "end" DESC
        LIMIT ?
    `
    return query(sql, limit)
}

export async function getActiveArtistStreak({artistId}) {
    const sql = `
        SELECT *
        FROM artist_streak
        WHERE
            artist_id = ? AND
            active
    `
    return querySingle(sql, artistId)
}

export async function getTimestampThreshold(threshold) {
    const sql = `
        SELECT APPROX_QUANTILE(timestamp, ?::float)
        FROM song_listen
    `
    return DateTime.fromMillis(await querySingleColumn(sql, threshold))
}

export async function getArtistRankingsWithMovement(threshold) {
    const sql = `
        WITH listen_group AS (
            WITH listen AS (
                SELECT *
                FROM artist_listen
            )
            SELECT
                *,
                ROW_NUMBER() OVER (ORDER BY count DESC, duration_ms DESC, entity_id ASC) as rank
            FROM top_entities()
        ), old_listen_group AS (
            WITH listen AS (
                SELECT *
                FROM artist_listen
                WHERE timestamp <= ?::timestamp
            )
            SELECT
                *,
                ROW_NUMBER() OVER (ORDER BY count DESC, duration_ms DESC, entity_id ASC) as rank
            FROM top_entities()
        )
        SELECT
            g.*,
            og.rank as old_rank
        FROM listen_group g
        LEFT JOIN old_listen_group og ON g.entity_id = og.entity_id
        ORDER BY g.rank
    `
    return query(sql, threshold)
}

export async function getRankings({table, start, end}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM query_table(?)
            WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
        )
        SELECT
            *,
            ROW_NUMBER() OVER (ORDER BY count DESC, duration_ms DESC, entity_id ASC) as rank
        FROM top_entities()
    `
    return query(sql, table, start, end)
}

export async function getRankedArtists({start, end}) {
    const sql = `
        WITH stage1 AS (
            SELECT
                artist_id,
                song_id,
                COUNT(*) as count,
                SUM(duration_ms) as duration_ms
            FROM artist_listen
            WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
            GROUP BY artist_id, song_id
        )
        SELECT
            artist_id,
            artist_id as entity_id,
            SUM(count) as count,
            SUM(duration_ms) as duration_ms,
            ROW_NUMBER() OVER (ORDER BY SUM(count) DESC) as rank,
            ARRAY_TO_JSON(MAX_BY(song_id, count, 5)) as song_ids
        FROM stage1
        GROUP BY artist_id
        ORDER BY count DESC
    `
    return query(sql, start, end)
}

export async function getTopDecades({start, end, limit}) {
    const sql = `
        WITH listen AS (
            SELECT
                FLOOR(release_year / 10) * 10 as entity_id,
                sl.song_id,
                sl.timestamp,
                sl.duration_ms,
                sl.weight
            FROM song_listen sl
            JOIN song s ON sl.song_id = s.id
            WHERE
                sl.timestamp BETWEEN ?::timestamp AND ?::timestamp AND
                s.release_year IS NOT NULL
        )
        SELECT *
        FROM top_entities()
        LIMIT ?
    `
    return query(sql, start, end, limit)
}

export async function getTopSongsPerDecade({start, end, limitPerDecade}) {
    const sql = `
        WITH listen AS (
            SELECT
                FLOOR(release_year / 10) * 10 as decade,
                sl.song_id,
                sl.duration_ms,
                sl.timestamp
            FROM song_listen sl
            JOIN song s ON sl.song_id = s.id
            WHERE
                sl.timestamp BETWEEN ?::timestamp AND ?::timestamp AND
                s.release_year IS NOT NULL
        )
        SELECT
            decade,
            song_id,
            COUNT(*) as count,
            SUM(duration_ms) as duration_ms,
            ROW_NUMBER() OVER (PARTITION BY decade ORDER BY count DESC) as rank
        FROM listen
        GROUP BY decade, song_id
        QUALIFY rank <= ?
    `
    return query(sql, start, end, limitPerDecade)
}

async function query(sql, ...args) {
    const result = await execute(sql, ...args)
    return result.toArray()
        .map(row => row.toJSON())
        .map(camelcaseKeys)
}

async function querySingle(sql, ...args) {
    const results = await query(sql, ...args)
    if(results.length === 0) {
        return null
    } else if(results.length === 1) {
        return results[0]
    } else {
        throw new Error(`Expected a single row, got ${results.length}`)
    }
}

async function querySingleColumn(sql, ...args) {
    const result = await querySingle(sql, ...args)
    if(result === null) {
        return null
    }
    const keys = Object.keys(result)
    if(keys.length === 1) {
        return result[keys[0]]
    } else {
        throw new Error(`Expected a single column, got ${keys.length} columns`)
    }
}

async function execute(sql, ...args) {
    const database = await getDatabase()

    args = args.map(mapArg)
    const conn = await database.connect()
    const statement = await conn.prepare(sql)
    return await statement.query(...args)
}

function mapArg(arg) {
    if (DateTime.isDateTime(arg)) {
        return arg.toSQL({includeOffset: false})
    } else {
        return arg
    }
}
