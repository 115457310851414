import {useAutoQuery} from "../../utils";
import {Card, CardContent, CardHeader, CardTitle} from "../ui/card";
import {SongCard} from "../SongCard";
import {Star} from "lucide-react";
import {getNewlyDiscoveredSongs} from "../../db/DbService";
import {DynamicGrid} from "../DynamicGrid";

export function NewDiscoveriesCard() {
    const {data: groups, isPending} = useAutoQuery(getNewlyDiscoveredSongs, {
        limit: 100,
    })

    if(isPending) {
        return
    }

    return (
        <Card>
            <CardHeader className="flex flex-col gap-1">
                <CardTitle className="flex items-center gap-2">
                    <Star className="h-6 w-6"/>
                    New Discoveries
                </CardTitle>
            </CardHeader>
            <CardContent>
                <DynamicGrid className="gap-2" targetWidthInRem={10}>
                    {groups.map(group => <SongCard songId={group.entityId} key={group.entityId}/>)}
                </DynamicGrid>
            </CardContent>
        </Card>
    )
}