import { Button } from "./ui/button"
import { ExternalLinkIcon } from "lucide-react"
import React from "react"

export function OpenInSpotifyButton({url}) {
    return (
        <a href={url} target="_blank" rel="noopener noreferrer">
            <Button variant="secondary" className="flex flex-row gap-1">
                <ExternalLinkIcon className="w-5 h-5"/>
                Open in Spotify
            </Button>
        </a>
    )
}