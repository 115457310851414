import {useArtist} from "../stores/ArtistStore"
import {Link} from "react-router-dom"
import {Card, CardContent, CardHeader} from "./ui/card"
import {Avatar, AvatarFallback, AvatarImage} from "./ui/avatar"
import React from "react"
import {Badge} from "./ui/badge"

export function ArtistCard({artistId}) {
    const {data: artist, isPending} = useArtist(artistId)

    if(isPending) {
        return
    }

    return (
        <Link to={`/artist/${artist.id}`}>
            <Card className="p-1 overflow-hidden border border-background">
                <CardHeader className="w-full flex items-center justify-center">
                    <Avatar className="w-[80%] aspect-square drop-shadow-xl">
                        <AvatarImage src={artist.images[0]?.url} alt={artist.name}/>
                        <AvatarFallback>{artist.name[0]}</AvatarFallback>
                    </Avatar>
                </CardHeader>
                <CardContent className="flex flex-col items-center gap-1 w-full p-1">
                    <div className="flex flex-col items-center w-full max-w-full">
                        <h3 className="font-semibold text-lg line-clamp-1">{artist.name}</h3>
                        <div className="flex justify-center w-full gradient-mask-r-70 h-[1.5rem]">
                            <div className="flex flex-row gap-1 w-fit overflow-x-hidden">
                                {artist.genres.map(genre => <GenreBadge genre={genre} key={genre.id}/>)}
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </Link>
    )
}

function GenreBadge({genre}) {
    return (
        <Badge className="line-clamp-1 flex-shrink-0 h-fit" variant="outline">
            {genre.name}
        </Badge>
    )
}
