import React from "react"
import {Link, useParams} from "react-router-dom"
import {OpenInSpotifyButton} from "../../components/OpenInSpotifyButton"
import {ArtistRankCard} from "../../components/ArtistRankCard"
import {ArtistListeningTimeCard} from "../../components/ArtistListeningTimeCard"
import {ArtistTotalListensCard} from "../../components/ArtistTotalListensCard"
import {getArtistName, useIsMobile} from "../../utils"
import {useArtist} from "../../stores/ArtistStore"
import {Badge} from "../../components/ui/badge"
import {DiscographyTab} from "./DiscographyTab"
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../../components/ui/tabs"
import {TimelineTab} from "./TimelineTab"
import {StatsTab} from "./StatsTab"
import {ArtistLongestStreakCard} from "../../components/ArtistLongestStreakCard"
import {Textfit} from "react-textfit"

export function ArtistPage() {
    const {artistId} = useParams()

    return (
        <div className="flex flex-col items-center gap-4 w-full">
            <Header artistId={artistId}/>
            <Summary artistId={artistId}/>
            <Body artistId={artistId}/>
        </div>
    )
}

function Header({artistId}) {
    const isMobile = useIsMobile()
    if(isMobile) {
        return <MobileHeader artistId={artistId}/>
    } else {
        return <DesktopHeader artistId={artistId}/>
    }
}

function MobileHeader({artistId}) {
    const {data: artist, isPending} = useArtist(artistId)

    if(isPending) {
        return
    }

    const artistName = getArtistName(artist)

    return (
        <div className="w-screen relative -mx-2 -mt-2">
            <img src={artist.images[0].url} alt={artistName} className="absolute h-full w-full object-cover brightness-50"/>
            <div className="relative w-full h-full flex justify-start items-end backdrop-blur-sm p-3">
                <div className="flex flex-col w-full gap-2">
                    <img src={artist.images[0].url} alt={artist.name} className="h-full aspect-square object-cover rounded-md drop-shadow-xl"/>
                    <div className="flex flex-col gap-2 w-full">
                        <Textfit className="text-white font-black flex-grow w-full h-12 flex flex-col-reverse leading-none overflow-hidden">
                            {artist.name}
                        </Textfit>
                        <div className="flex gap-2">
                            {artist.genres.map(genre => <GenreBadge genre={genre}/>)}
                        </div>
                        <div className="flex flex-row gap-2">
                            {artist.spotifyUrl && <OpenInSpotifyButton url={artist.spotifyUrl}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function DesktopHeader({artistId}) {
    const {data: artist, isPending} = useArtist(artistId)

    if(isPending) {
        return
    }

    const artistName = getArtistName(artist)

    return (
        <div className="w-full h-[20rem] rounded-lg relative overflow-hidden">
            <img src={artist.images[0].url} alt={artistName} className="absolute h-full w-full object-cover brightness-50"/>
            <div className="relative w-full h-full flex justify-start items-end backdrop-blur-sm p-6">
                <div className="flex flex-row w-full h-2/3 gap-2">
                    <img src={artist.images[0].url} alt={artist.name} className="h-full aspect-square object-cover rounded-md drop-shadow-xl"/>
                    <div className="flex flex-col gap-2 w-full">
                        <Textfit className="text-white font-black flex-grow w-full flex flex-col-reverse leading-none overflow-hidden">
                            {artist.name}
                        </Textfit>
                        <div className="flex gap-2">
                            {artist.genres.map(genre => <GenreBadge genre={genre}/>)}
                        </div>
                        <div className="flex flex-row gap-2">
                            {artist.spotifyUrl && <OpenInSpotifyButton url={artist.spotifyUrl}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function GenreBadge({genre}) {
    return (
        <Link to={`/genre/${genre.id}`} key={genre.id}>
            <Badge variant="secondary" className="text-sm text-nowrap">
                {genre.name}
            </Badge>
        </Link>
    )
}

function Summary({artistId}) {
    return (
        <div className="grid w-full gap-4 grid-cols-2 lg:grid-cols-4">
            <ArtistTotalListensCard artistId={artistId}/>
            <ArtistListeningTimeCard artistId={artistId}/>
            <ArtistLongestStreakCard artistId={artistId}/>
            <ArtistRankCard artistId={artistId}/>
        </div>
    )
}

function Body({artistId}) {
    return (
        <Tabs defaultValue="stats" className="w-full">
            <TabsList>
                <TabsTrigger value="stats">Stats</TabsTrigger>
                <TabsTrigger value="discography">Discography</TabsTrigger>
                <TabsTrigger value="timeline">Timeline</TabsTrigger>
            </TabsList>
            <TabsContent value="stats">
                <StatsTab artistId={artistId}/>
            </TabsContent>
            <TabsContent value="discography">
                <DiscographyTab artistId={artistId}/>
            </TabsContent>
            <TabsContent value="timeline">
                <TimelineTab artistId={artistId}/>
            </TabsContent>
        </Tabs>
    )
}