import React from "react"
import { useParams } from "react-router-dom"
import {
    epoch,
    formatDateShort,
    formatListenDuration,
    formatTrackDuration,
    roundedNow,
    useAutoQuery,
    useIsMobile,
} from "../utils"
import { Clock3Icon, HeadphonesIcon, SparklesIcon, TrendingUpIcon } from "lucide-react"
import { OpenInSpotifyButton } from "../components/OpenInSpotifyButton"
import { ArtistCredit } from "../components/ArtistCredit"
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card"
import { useAlbum } from "../stores/AlbumStore"
import { getAlbumSummary } from "../db/DbClient"
import { getSongsGraph } from "../db/DbService"
import { SongGraph } from "../components/graph/SongGraph"
import { TopSongs } from "../components/TopSongs"
import { Textfit } from "react-textfit"
import { InterpunctList } from "../components/InterpunctList"

function Header({albumId}) {
    const isMobile = useIsMobile()

    if(isMobile) {
        return <MobileHeader albumId={albumId}/>
    } else {
        return <DesktopHeader albumId={albumId}/>
    }
}

function DesktopHeader({albumId}) {
    const {data: album, isPending} = useAlbum(albumId)

    if (isPending) {
        return null
    }

    return (
        <div className="w-full h-[20rem] rounded-lg relative overflow-hidden">
            <img src={album.images[0].url} alt={album.name} className="absolute h-full w-full object-cover brightness-50"/>
            <div className="relative w-full h-full flex justify-start items-end backdrop-blur-sm p-6">
                <div className="flex flex-row w-full h-2/3 gap-2">
                    <img src={album.images[0].url} alt={album.name} className="h-full aspect-square object-cover rounded-md drop-shadow-xl"/>
                    <div className="flex flex-col gap-1 w-full">
                        <Textfit mode="multi" className="text-white font-black flex-grow w-full flex flex-col-reverse leading-none">
                            {album.name}
                        </Textfit>
                        <ArtistCredit artistCredit={album.artistCredit} className="text-white text-2xl"/>
                        <InterpunctList>
                            {album.releaseDate.year && <span key="releaseDate">{album.releaseDate.year}</span>}
                            <span key="duration">{formatTrackDuration(2696000)}</span>
                        </InterpunctList>
                        <div className="flex flex-row gap-2">
                            {album.spotifyUrl && <OpenInSpotifyButton url={album.spotifyUrl}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function MobileHeader({albumId}) {
    const {data: album, isPending} = useAlbum(albumId)

    if (isPending) {
        return null
    }

    return (
        <div className="w-screen relative -mt-2 -mx-2">
            <img src={album.images[0].url} alt={album.name} className="absolute h-full w-full object-cover brightness-50"/>
            <div className="relative w-full h-full flex justify-start items-end backdrop-blur-sm p-3">
                <div className="flex flex-col w-full h-2/3 gap-2">
                    <img src={album.images[0].url} alt={album.name} className="h-full aspect-square object-cover rounded-md drop-shadow-xl"/>
                    <div className="flex flex-col gap-1 w-full">
                        <Textfit mode="multi" className="text-white font-black flex-grow w-full h-12 flex flex-col-reverse leading-none">
                            {album.name}
                        </Textfit>
                        <ArtistCredit artistCredit={album.artistCredit} className="text-white text-2xl"/>
                        <InterpunctList>
                            {album.releaseDate.year && <span>{album.releaseDate.year}</span>}
                            <span>{formatTrackDuration(2696000)}</span>
                        </InterpunctList>
                        <div className="flex flex-row gap-2">
                            {album.spotifyUrl && <OpenInSpotifyButton url={album.spotifyUrl}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function TopRecordingsGraph({albumId}) {
    const start = epoch()
    const end = roundedNow()
    const {data: graph, isPending} = useAutoQuery(getSongsGraph, {
        albumId,
        start,
        end,
        distinctCount: 4,
    })

    if (isPending) {
        return
    }

    return (
        <SongGraph
            graph={graph}
            className="w-full h-[25rem]"
        />
    )
}

function Summary({albumId}) {
    return (
        <div className="grid w-full gap-4 md:grid-cols-2 lg:grid-cols-4">
            <ListenCountSummaryCard albumId={albumId}/>
            <ListeningTimeSummaryCard albumId={albumId}/>
            <RankSummaryCard albumId={albumId}/>
            <FirstListenSummaryCard albumId={albumId}/>
        </div>
    )
}

function ListenCountSummaryCard({albumId}) {
    const {
        data: allTimeSummary,
        isPending: allTimeSummaryPending,
    } = useAutoQuery(getAlbumSummary, {
        albumId,
        start: epoch(),
        end: roundedNow()
    })
    const {
        data: recentSummary,
        isPending: recentSummaryPending,
    } = useAutoQuery(getAlbumSummary, {
        albumId,
        start: roundedNow().minus({days: 30}),
        end: roundedNow()
    })

    if (allTimeSummaryPending || recentSummaryPending) {
        return
    }

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Total Listens</CardTitle>
                <HeadphonesIcon className="w-4 h-4 text-muted-foreground"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">{allTimeSummary.listenCount}</div>
                <p className="text-xs text-muted-foreground">+{recentSummary.listenCount} recently</p>
            </CardContent>
        </Card>
    )
}

function ListeningTimeSummaryCard({albumId}) {
    const start = epoch()
    const end = roundedNow()
    const recentStart = roundedNow().minus({days: 30})
    const {
        data: allTimeSummary,
        isPending: allTimeSummaryPending,
    } = useAutoQuery(getAlbumSummary, {albumId, start, end})
    const {
        data: recentSummary,
        isPending: recentSummaryPending,
    } = useAutoQuery(getAlbumSummary, {albumId, recentStart, end})

    if (allTimeSummaryPending || recentSummaryPending) {
        return
    }

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Listening Time</CardTitle>
                <Clock3Icon className="w-4 h-4 text-muted-foreground"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">{formatListenDuration(allTimeSummary.durationMs)}</div>
                <p className="text-xs text-muted-foreground">+{formatListenDuration(recentSummary.durationMs)} from
                    last month</p>
            </CardContent>
        </Card>
    )
}

function RankSummaryCard({albumId}) {
    const {data: summary, isPending} = useAutoQuery(getAlbumSummary, {
        albumId,
        start: epoch(),
        end: roundedNow()
    })

    if (isPending) {
        return
    }

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Album Rank</CardTitle>
                <TrendingUpIcon className="w-4 h-4 text-muted-foreground"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">#{summary.rank}</div>
                <p className="text-xs text-muted-foreground">Your most listened album</p>
            </CardContent>
        </Card>
    )
}

function FirstListenSummaryCard({albumId}) {
    const {data: summary, isPending} = useAutoQuery(getAlbumSummary, {
        albumId,
        start: epoch(),
        end: roundedNow()
    })

    if (isPending) {
        return
    }

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-sm font-medium">Discovery</CardTitle>
                <SparklesIcon className="w-4 h-4 text-muted-foreground"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">{formatDateShort(summary.firstListen)}</div>
                <p className="text-xs text-muted-foreground">Your first listen</p>
            </CardContent>
        </Card>
    )
}

export function AlbumPage() {
    const {albumId} = useParams()

    return (
        <div className="flex flex-col items-center gap-4 w-full">
            <Header albumId={albumId}/>
            <Summary albumId={albumId}/>
            <TopRecordingsGraph albumId={albumId}/>
            <div className="grid sm:grid-cols-2 gap-4">
                <TopSongs albumId={albumId} start={epoch()} end={roundedNow()} limit="20"/>
            </div>
        </div>
    );
}
