import {Bubbles} from "./Bubbles"
import {useSongs} from "../stores/SongStore"

export function SongBubbles({groups}) {
    const songIds = groups.map(group => group.entityId);
    const results = useSongs(songIds);

    const songsById = results
        .filter(result => !result.isPending)
        .reduce((acc, {data: song}) => {
            acc[song.id] = song
            return acc
        }, {})

    const data = groups
        .map(group => {
            const song = songsById[group.entityId];
            return {
                id: group.entityId,
                name: song?.name,
                value: group.count,
                imageUrl: song?.images[0]?.url,
                path: `/song/${group.entityId}`
            };
        })

    return <Bubbles data={data}/>
}