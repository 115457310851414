import {cn, useMeasureRem} from "../utils";
import {useMemo} from "react";

export function DynamicGrid({ children, targetWidthInRem, className }) {
    const [ref, { width }] = useMeasureRem();

    const columns = useMemo(() => {
        if (!children?.length) return 0;
        const targetColumns = Math.max(1, Math.round(width / targetWidthInRem));
        const squareSize = Math.floor(Math.sqrt(children.length));
        return Math.min(targetColumns, squareSize);
    }, [children?.length, width, targetWidthInRem]);

    if (!children?.length) return null;

    return (
        <div
            className={cn("grid gap-4", className)}
            style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
            ref={ref}
        >
            {children.slice(0, columns * columns)}
        </div>
    );
}