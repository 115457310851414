import {useArtists} from "../stores/ArtistStore"
import {Bubbles} from "./Bubbles"

export function ArtistBubbles({ groups }) {
    const artistIds = groups.map(group => group.entityId);
    const results = useArtists(artistIds);

    const artistsById = results
        .filter(result => !result.isPending)
        .reduce((acc, {data: artist}) => {
            acc[artist.id] = artist
            return acc
        }, {})

    const data = groups
        .map(group => {
            const artist = artistsById[group.entityId];
            return {
                id: group.entityId,
                name: artist?.name,
                value: group.count,
                imageUrl: artist?.images[0]?.url,
                path: `/artist/${group.entityId}`
            };
        })

    return <Bubbles data={data}/>
}
