import {useStoreWithEqualityFn} from "zustand/traditional"
import {databaseStore} from "../stores/DatabaseStore"

export function DatabaseLoadingPage() {
    const {stage} = useStoreWithEqualityFn(databaseStore)

    return (
        <div className="flex flex-col flex-grow items-center justify-center">
            <div className="text-center">
                <div className="inline-flex mb-4" aria-label="Loading">
                    <div className="w-12 h-12 rounded-full bg-accent animate-ping"></div>
                    <div className="absolute w-12 h-12 rounded-full bg-accent"></div>
                </div>
                <h1 className="text-3xl font-bold text-foreground mb-2">Loading your music insights</h1>
                <p className="text-foreground">Please wait while we crunch the numbers...</p>
                <p className="text-muted-foreground">{stage}</p>
            </div>
        </div>
    )
}